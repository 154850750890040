import { Card, Col, Flex, Row } from "antd";
import React, { useEffect } from "react";
import { Content } from "antd/es/layout/layout";
import LoginForm from "../../../components/auth/LoginForm";
import useGetUserData from "../../../hooks/useGetUserData";
import { useNavigate } from "react-router-dom";
import Rectangle from "../../../assets/Rectangle.png";
import logo from "../../../assets/footer-logo.png";
import icon from "../../../assets/icon-white1.png";

export default function Login() {
  const navigate = useNavigate();
  const { isLoggedIn } = useGetUserData();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);
  if (isLoggedIn) return null;

  return (
    <Content>
      <div className="absolute bg-[#0E103A] xl:h-[95px] w-full mt-[50px]">
        <Row className="text-center pt-5" justify={"space-around"}>
          <Col className="text-white text-center pb-3">
            <span>
              <img src={icon} alt="icon" className="xl:w-[40px] xl:h-[40px] xl:mt-1 xl:mr-10" />
            </span>
            <span className="xl:text-5xl text-xl">
              Welcome To IBDL
            </span>
          </Col>
          <Col >
          </Col>
        </Row>
      </div>

      <Flex
        justify="center"
        align="center"
        style={{
          height: "100vh",
          backgroundImage: `url(${Rectangle})`,
          backgroundSize: "432px 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
        vertical
      >
        <div className="absolute xl:right-[100px]">
          <img src={logo} alt="Logo" style={{ width: "240px", height: "80px" }} />
        </div>

        <Card className="xl:px-8 xl:pt-7 xl:mr-80 xl:mt-28"
          bordered={false}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LoginForm />
          </div>
        </Card>
      </Flex>
    </Content>
  );
}
