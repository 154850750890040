import React, { useState } from 'react';
import { Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { decodeResponse, decryptData } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import DeleteModal from '../../../common/DeleteModal';
import { deleteData } from '../../../../network/deleteData';
import FilterTable from '../../../common/filtersTables/FilterTable';
import { DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../../common/UploadFile';
import CustomTable from '../../../common/CustomTable';
import { ColumnsType } from 'antd/es/table';
import AddExaminee from './AddExaminee';

interface ExamUserProps {
  exam_scheduling_data: any;
}

interface DataType {
  id: React.Key;
  name: string;
  email: string;
  examinable_type: string;
}

const ExamUser: React.FC<ExamUserProps> = ({ exam_scheduling_data }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "examinee",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "examinee",
    type: "get",
    exam_schedule_id: exam_scheduling_data?.id,
  };

  const { data: listExaminees, isLoading, isFetching } = useQuery(
    [queryKeys.listExaminees, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExaminees);
  const decryptedData = decodedResponse?.data.map((item: { examinable_type: any, examinable: { name: any; email: any; phone: any; }; }) => {

    return {
      ...item,
      examinable: {
        ...item?.examinable,
        name: decryptData(item?.examinable?.name),
        email: decryptData(item?.examinable?.email),
        phone: decryptData(item?.examinable?.phone),
        type: item?.examinable_type,
      },
    };
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: ['examinable', 'name'],
      key: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      dataIndex: ['examinable', 'email'],
      key: 'email',
      render: (text: any, record: any) => {
        if (record.examinable_type === "User") {
          return text;
        }
        if (record?.examinable_type === "Batch") {
          return decryptData(record?.examinable?.corporate?.email);
        }
      },
    },
    {
      title: <FormattedMessage id={'phone'} />,
      dataIndex: ['examinable', 'phone'],
      key: 'phone',
      render: (text: any, record: any) => {
        if (record.examinable_type === "User") {
          return text;
        }
        if (record?.examinable_type === "Batch") {
          return decryptData(record?.examinable?.corporate?.phone);
        }
      },
    },
    {
      title: <FormattedMessage id={'type'} />,
      dataIndex: 'examinable_type',
      key: 'examinable_type',
      filters: [
        { text: 'User', value: 'User' },
        { text: 'Batch', value: 'Batch' },
      ],
      onFilter: (value: any, record: any) => record.examinable_type === value,
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='exams' />,
      href: '/exams',
    },
    {
      title: <FormattedMessage id='users' />,
      href: '',
    }
  ];

  return (
    <>
      <FilterTable isFilter={false} items={items} onSearch={(value) => setSearchTerm(value)} buttonText={<FormattedMessage id='add_exam_users' />} tabName={<FormattedMessage id='exam_users' />} onClick={() => showModal('add')} onClickUpload={() => showModal('upload')} />
      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"e-learning"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listELearnings} />
      <AddExaminee visible={isModalVisible && modalAction === 'add'} onClose={closeModal} exam_scheduling_data={exam_scheduling_data} />
      <DeleteModal visible={isModalVisible && modalAction === 'delete'} onDelete={deleteData} data={deleteValue} confirmationHeader={<FormattedMessage id='exam_users' />} queryKey={queryKeys.listExaminees} onClose={closeModal} />
      <CustomTable scroll={{ x: 'max-content' }} isLoading={isLoading} isFetching={isFetching} columns={columns} data={decryptedData} />
    </>
  );
}

export default ExamUser;
