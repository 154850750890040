import React from "react";
import { Tabs, TabsProps } from "antd";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import SubCategory from "../../components/assessment/cpatAssessment/SubCategory";
import AdviceTable from "../../components/assessment/cpatAssessment/AdviceTable";
import StatementTable from "../../components/assessment/cpatAssessment/Statement";
import Company from "../../components/assessment/cpatAssessment/Company";
import Position from "../../components/assessment/cpatAssessment/Position";
import QuestionsBank from "../../components/assessment/cpatAssessment/QuestionsBank";
import ExamSchedule from "../../components/assessment/cpatAssessment/examScheduling/ExamSchedule";

export default function CpatAssessmentData() {
  const location = useLocation();
  const data = location?.state.assessment_data;

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'categories'} />,
      children: <SubCategory />,
    },
    {
      key: '3',
      label: <FormattedMessage id={'statements'} />,
      children: <StatementTable assessmentData={data} />,
    },
    {
      key: '5',
      label: <FormattedMessage id={'company'} />,
      children: <Company assessmentData={data} />,
    },
    {
      key: '6',
      label: <FormattedMessage id={'position'} />,
      children: <Position assessmentData={data} />,
    },
    {
      key: '2',
      label: <FormattedMessage id={'advises'} />,
      children: <AdviceTable assessmentData={data} />,
    },
    {
      key: '7',
      label: <FormattedMessage id={'questions_bank'} />,
      children: <QuestionsBank assessmentData={data} />,
    },
    {
      key: '8',
      label: <FormattedMessage id={'exam_schedule'} />,
      children: <ExamSchedule assessmentData={data} />,
    },
  ];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'assessment'} />} />

      <div className="customTab">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-3"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>
  );
}

