import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import ListChapters from "../../components/certificate/chapter/ListChapters";

export default function Chapters() {
  const location = useLocation();
  const data = location?.state.level_data;

  return (
    <>
      <CustomHeader title={<div>{data.name} <FormattedMessage id={'chapters'} /></div>} />

      <div className="customTab2 mt-8">
        <ListChapters
          levelData={data}
        />
      </div>
    </>
  );
}

