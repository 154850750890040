import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddELearning from './AddELearning';
import Meta from 'antd/es/card/Meta';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateELearning from './UpdateELearning';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../common/UploadFile';

function ListELearnings() {
  const [editELearningData, setEditELearningData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const [searchTerm, setSearchTerm] = useState('');

  const handlePageChange = (page: number) => setCurrentPage(page);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditELearningData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => setModalVisible(false);

  const deleteValue = {
    mode: "e-learning",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "e-learning",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null,
  };

  const { data: listELearnings, isLoading, isFetching } = useQuery(
    [queryKeys.listELearnings, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listELearnings);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['certificate'];

    return Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => [
        key,
        excludedFields.includes(key) ? item[key] : value,
      ])
    );
  }) || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = decryptedData.slice(indexOfFirstItem, indexOfLastItem);

  const renderELearning = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decryptedData || decryptedData?.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} >
          {currentItems.map((item: any) => (
            <Col className='mb-4' xl={6} lg={6} md={12} sm={12} xs={24}>
              <Card title={item?.certificate?.name}
                actions={[
                  <Link to="/e-learning/data" className='text-[#ED1D23]' state={{ e_learning_data: item }}><EyeOutlined /></Link>,
                  <EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} />,
                  <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />,
                ]}
              >
                <Meta
                  description={<div style={{ height: "90px" }}>{truncateText(decodeURIComponent(item?.instruction), 120)}</div>}
                />
              </Card>
            </Col>
          ))}
        </Row>
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={decryptedData?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  const truncateText = (text: string, maxLength: number) =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_e_learning' />}
        tabName={<FormattedMessage id='e_learnings' />}
        onClick={() => showModal('add')}
        items={[{ title: <FormattedMessage id='e_learnings' /> }]}
        isUpload={false}
        isFilter={false}
        onSearch={(value) => setSearchTerm(value)}
      />
      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />
      <AddELearning visible={isModalVisible && modalAction === 'add'} onClose={closeModal} />
      <UpdateELearning visible={isModalVisible && modalAction === 'update'} onClose={closeModal} eLearningData={editELearningData} />
      <DeleteModal visible={isModalVisible && modalAction === 'delete'} onDelete={deleteData} data={deleteValue} confirmationHeader={<FormattedMessage id='e_learning' />} queryKey={queryKeys.listELearnings} onClose={closeModal} />

      {renderELearning()}
    </>
  );
}

export default ListELearnings;