import React from 'react';
import { Col, Flex, Row } from 'antd';
import Contacts from '../../assets/common/Contacts';
import Phone from '../../assets/common/Phone';
import Address from '../../assets/common/Address ';
import Message from '../../assets/common/Message';
import { FormattedMessage } from 'react-intl';

interface BasicInfoProps {
  corporateData: any;
}

const BasicInfo: React.FC<BasicInfoProps> = ({ corporateData }) => {
  return (
    <div className='mt-5'>

      <Row className='bg-[#FAFAFA] p-3'>
        <Col className='text-[#0E103A] text-[16px] text-center font-extrabold'>
          <FormattedMessage id={'filed'} />
        </Col>
        <Col className='px-2 text-[16px] text-center'>
          {corporateData?.filed}
        </Col>
      </Row>
      <Row className='mb-4 bg-[#FAFAFA] p-3'>
        <Col className='text-[#0E103A] text-[16px] text-center font-extrabold'>
          <FormattedMessage id={'corporate_type'} />

        </Col>
        <Col className='px-2 text-[16px] text-center'>
          {corporateData?.corporate_type}
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3'>
              <FormattedMessage id={'basic_information'} />
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Contacts /></div>
                <div>{corporateData?.name}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Phone /></div>
                <div>{corporateData?.phone}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Message /></div>
                <div>{corporateData?.email}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row >
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3'>
              <FormattedMessage id={'contact_info'} />
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Contacts /></div>
                <div>
                  {corporateData?.contact_person}
                  <br />
                  {corporateData?.contact_title}
                </div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Phone /></div>
                <div>{corporateData?.contact_phone}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Message /></div>
                <div>{corporateData?.contact_email}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row className='mb-4'>
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3'>
              <FormattedMessage id={'location'} />
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Address /></div>
                <div>{corporateData?.country}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Address /></div>
                <div>{corporateData?.country}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>



    </div>
  );
}

export default BasicInfo;
