enum questionTypeEnum {
  MCQ = "mcq",
  TrueFalse = "true_false",
}

const roleLabelsEn: { value: string; label: string }[] = [
  { value: questionTypeEnum.MCQ, label: 'MCQ' },
  { value: questionTypeEnum.TrueFalse, label: 'True|False' }
];

const roleLabelsAr: { value: string; label: string }[] = [
  { value: questionTypeEnum.MCQ, label: 'اختيار من متعدد' },
  { value: questionTypeEnum.TrueFalse, label: 'صح أو خطأ' },
];

export { questionTypeEnum, roleLabelsEn, roleLabelsAr };