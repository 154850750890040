import { Button, Flex, Form, Input, InputRef, Modal, Space, Table, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../../services/react-query/queryKeys';
import { StoreData } from '../../../../../network/storeDate';
import queryClient from '../../../../../services/react-query/queryClient';
import { FormattedMessage } from 'react-intl';
import { decodeResponse, decryptData, decryptItem } from '../../../../../helpers/apiUtils';
import { FetchData } from '../../../../../network/fetchData';
import { useMemo, useRef, useState } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { TableColumnType } from 'antd';
import Highlighter from "react-highlight-words";

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  exam_scheduling_data: any;
}

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  type: string;
  corporate: any;
}
type DataIndex = keyof DataType;

const AddExaminee: React.FC<AddModalProps> = ({
  exam_scheduling_data,
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [selectedExamineeIds, setSelectedExamineeIds] = useState<DataType[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            className="bg-[#1d1d39] h-full text-white text-lg"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            className='text-[#1d1d39]'
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className='text-[#1d1d39]'
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className='text-[#1d1d39]'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onCancel = () => {
    form.resetFields();
    setSelectedExamineeIds([]);
    setSearchText('');
    onClose();
  };

  const { mutate: addMutation, isLoading } = useMutation(StoreData, {
    onSuccess: () => {
      setSelectedExamineeIds([]);
      queryClient.invalidateQueries([queryKeys.listAssessmentExaminees]);
      message.success("Submit success!");
      form.resetFields();
      onClose();
    },
    onError: (error) => {
      message.error("Error Adding");
    },
  });

  const onFinish = async () => {
    const transformedValues = {
      examinees: selectedExamineeIds.map((selectedItem) => ({
        examinable_id: selectedItem.key,
        examinable_type: selectedItem.type === 'User' ? 'App\\Models\\User' : 'App\\Models\\Batch',
      })),
    };

    const formData = {
      mode: "assessment_examinee",
      type: "store",
      assessment_exam_schedule_id: exam_scheduling_data.id,
      ...transformedValues,
    };

    addMutation(formData);
  };

  const { data: listAssessmentExaminees } = useQuery(
    [queryKeys.listAssessmentExaminees],
    () => FetchData({
      mode: 'assessment_examinee',
      type: 'get',
      assessment_exam_schedule_id: exam_scheduling_data?.id,
    }),
    {
      keepPreviousData: true,
    },
  );

  const existingExamineeIds = useMemo(
    () => decodeResponse(listAssessmentExaminees)?.data.map((item: any) => item?.user?.id),
    [listAssessmentExaminees],
  );

  const { data: listUser } = useQuery(
    [queryKeys.listUser],
    () => FetchData({ mode: 'auth', type: 'all_user' }),
  );

  const decodedResponse = decodeResponse(listUser);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['role'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const usersWithRoleUser = decryptedData
    ?.filter((item: any) => item?.role === 'user' && !existingExamineeIds?.includes(item?.id));

  const { data: listBatchs } = useQuery(
    [queryKeys.listBatchs],
    () => FetchData({
      mode: 'batch',
      type: 'get',
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedBatchResponse = decodeResponse(listBatchs);
  const decryptedBatches = (decodedBatchResponse?.data || [])
    .map((item: any) => {
      const decryptedItem = decryptItem(item);
      const excludedFields = ['start_date', 'end_date', 'progress', 'corporate'];

      const itemWithoutDecryption = Object.fromEntries(
        Object.entries(decryptedItem).map(([key, value]) => {
          return excludedFields.includes(key) ? [key, item[key]] : [key, value];
        })
      );

      return itemWithoutDecryption;
    })
    .filter((item: { id: any; }) => !existingExamineeIds?.includes(item?.id));

  const columns = [
    {
      title: <FormattedMessage id="name" />,
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: <FormattedMessage id="email" />,
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      render: (text: any, record: DataType) => {
        if (record.type === 'User') {
          return text;
        }
        if (record?.corporate) {
          return decryptData(record?.corporate?.email);
        }
      },
    },
    {
      title: <FormattedMessage id="phone" />,
      dataIndex: 'phone',
      key: 'phone',
      render: (text: any, record: DataType) => {
        if (record.type === 'User') {
          return text;
        }
        if (record?.corporate) {
          return decryptData(record?.corporate?.phone);
        }
      },
    },
    {
      title: <FormattedMessage id="type" /> as React.ReactNode,
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'User', value: 'User' },
        { text: 'Batch', value: 'Batch' },
      ],
      onFilter: (value: any, record: DataType) => record.type === value,
    },
  ];

  const combinedData = [
    ...usersWithRoleUser?.map((user: any) => ({
      key: user?.id,
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      type: 'User',
    })) || [],
    ...decryptedBatches?.map((batch: any) => ({
      key: batch?.id,
      name: batch?.name,
      corporate: batch?.corporate,
      type: 'Batch',
    })) || [],
  ];

  const rowSelection = {
    selectedRowKeys: selectedExamineeIds.map((item) => item.key),
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedExamineeIds(selectedRows);
    },
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_examinees' />}
      width={1050}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Table rowSelection={rowSelection} columns={columns} dataSource={combinedData} className='mb-5' />

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default AddExaminee;

