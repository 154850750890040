import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import AssessmentTypes from "../../components/assessment/Assessment";

export default function Assessment() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'assessment'} />} />
      <AssessmentTypes />
    </>
  );
}


