import React from 'react';
import { Avatar, Card, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { decryptData } from '../../../../helpers/apiUtils';
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined

} from '@ant-design/icons';
interface BasicInfoProps {
  examinee_data: any;
}
const BasicInfo: React.FC<BasicInfoProps> = ({ examinee_data }) => {
  return (
    <div className='mt-5'>
      <Card className='mb-4' bordered={false}>
        <Row gutter={20}>
          <Col>
            <Avatar size={100} src="" icon={<UserOutlined />} />
          </Col>
          <Col className='mt-2'>
            <div className='text-[#2D3A49] text-[20px] font-extrabold mx-1'>{examinee_data?.user?.name}</div>
            <Row >
              <Col>
                <div className='text-[#2D3A49] text-[16px]'><MailOutlined className='mx-1' /><FormattedMessage id={'email'} />:</div>
              </Col>
              <Col>
                <div className='text-[16px] text-[#515B68]'>{examinee_data?.user?.email}</div>
              </Col>
            </Row>
            <Row >
              <Col>
                <div className='text-[#2D3A49] text-[16px]'><PhoneOutlined className='mx-1' /><FormattedMessage id={'phone'} />:</div>
              </Col>
              <Col>
                <div className='text-[16px] text-[#515B68]'>{examinee_data?.user?.phone}</div>
              </Col>
            </Row></Col>
        </Row>


      </Card>
      <Card className='bg-[#FAFAFA] mb-4'>
        <Row gutter={20}>
          <Col>
            <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'name'} />:</div>
          </Col>
          <Col>
            <div className='text-[16px] text-[#515B68]'>{decryptData(examinee_data?.exam_schedule?.exam_configuration?.exam_title)}</div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col>
            <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'scheduled_at'} />:</div>
          </Col>
          <Col>
            <div className='text-[16px] text-[#515B68]'>{examinee_data?.exam_schedule?.scheduled_at}</div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col>
            <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'exam_duration'} />:</div>
          </Col>
          <Col>
            <div className='text-[16px] text-[#515B68]'>{examinee_data?.exam_schedule?.exam_configuration?.exam_duration}</div>
          </Col>
        </Row>
        <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'instruction'} />:</div>
        <div className='text-[16px] text-[#515B68]'>{decryptData(examinee_data?.exam_schedule?.exam_configuration?.exam_notes)}</div>
      </Card>
    </div>
  );
}

export default BasicInfo;