enum statusEnum {
  Pending = "pending",
  Completed = "completed",
  Canceled = "canceled",
}

const status: { value: string; label: { en: string; ar: string } }[] = [
  { value: statusEnum.Pending, label: { en: 'Pending', ar: 'معلق' } },
  { value: statusEnum.Completed, label: { en: 'Completed', ar: 'مكتمل' } },
  { value: statusEnum.Canceled, label: { en: 'Canceled', ar: 'ملغى' } },
];

export { statusEnum, status };
