import React from 'react';
import { Card, Row, Col, Spin, Alert, Collapse, Flex, Badge } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { decodeResponse, decryptData } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { FormattedMessage } from 'react-intl';

interface BasicInfoProps {
  examineeData: any;
}

const QuestionTracking: React.FC<BasicInfoProps> = ({ examineeData }) => {
  const { user, exam_schedule_id: examScheduleId } = examineeData || {};

  const fetchParams = {
    mode: 'examinee',
    type: 'list_running_questions',
    user_id: user?.id,
    exam_schedule_id: examScheduleId,
  };

  const { data, error, isLoading } = useQuery(
    [queryKeys.listRunningQuestions, fetchParams.user_id, fetchParams.exam_schedule_id],
    () => FetchData(fetchParams)
  );

  if (isLoading) return (
    <Flex gap="middle" vertical className='mt-40'>
      <Spin tip={<FormattedMessage id='loading' />} size="large">
        <div className='spin-loading' />
      </Spin>
    </Flex>
  );

  if (error) return <Alert message="Error" description="Failed to load data." type="error" showIcon />;

  const decodedResponse = decodeResponse(data);
  const decryptedQuestions = decodedResponse?.data.map((item: any) => ({
    ...item,
    questions_bank: {
      ...item.questions_bank,
      question: decryptData(item.questions_bank.question),
      answers: item.questions_bank.answers.map((answer: any) => ({
        ...answer,
        answer_text: decryptData(answer.answer_text),
      })),
    },
  }));

  const uniqueAnsweredQuestions = decryptedQuestions.filter((q: any, index: number, self: any[]) => q.is_answered && self.findIndex(t => t.questions_bank.id === q.questions_bank.id && t.is_answered) === index);
  const uniqueUnsavedQuestions = decryptedQuestions.filter((q: any, index: number, self: any[]) => q.is_saved && !q.is_answered && self.findIndex(t => t.questions_bank.id === q.questions_bank.id && t.is_saved) === index);
  const remainingQuestions = decryptedQuestions.filter((q: any) => !q.is_answered);

  const scrollToQuestion = (index: number) => {
    const element = document.getElementById(`question-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      element.focus({ preventScroll: true });
    }
  };

  const QuestionCard = ({ question, index }: { question: any; index: number }) => (
    <Collapse className="mb-4" defaultActiveKey={[index]}
      bordered={false}
    >
      <Collapse.Panel
        header=
        {
          <span>
            {question.questions_bank.question}
          </span>
        }
        key={index}>
        <div id={`question-${index}`} className="bg-[#ECECEC] p-5 mb-4 rounded-lg shadow-sm">
          <Row gutter={[16, 16]}>
            {question.questions_bank.answers.map((answer: any, idx: number) => (
              <Col span={24} key={idx}>
                <Card
                  className={`w-full ${question?.answer_id === answer.id && answer.is_correct === 1
                    ? 'bg-green-100'
                    : question?.answer_id === answer.id && answer.is_correct !== 1
                      ? 'bg-red-100'
                      : ''
                    }`}
                >
                  {question.is_answered?.answer_id}
                  <div className="flex items-center">
                    {question?.answer_id === answer.id && answer.is_correct === 1 ? (
                      <CheckCircleOutlined className="text-green-500 mr-2" />
                    ) : question?.answer_id === answer.id && answer.is_correct !== 1 ? (
                      <CloseCircleOutlined className="text-red-500 mr-2" />
                    ) : null}
                    {decryptData(answer.answer)}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Collapse.Panel>
    </Collapse>
  );

  const renderQuestionStatus = (questions: any[], statusClass: string, textColor: string) => (
    questions.map((question, index: number) => (
      <div
        key={`${statusClass}-${question.questions_bank.id}`}
        className={`p-2 text-center ${statusClass} rounded ${textColor} cursor-pointer`}
        onClick={() => scrollToQuestion(decryptedQuestions.findIndex((q: { questions_bank: { id: any; }; }) => q.questions_bank.id === question.questions_bank.id))}
      >
        {decryptedQuestions.findIndex((q: { questions_bank: { id: any; }; }) => q.questions_bank.id === question.questions_bank.id) + 1}
      </div>
    ))
  );

  return (
    <div className="mt-5">
      <Flex justify='space-between' className='mb-3'>
        <div className="text-lg font-bold">
          <FormattedMessage id='questions' />: {decryptedQuestions.filter((q: any) => q.is_answered).length}/{decryptedQuestions.length}
        </div>
        <div>
          <Flex justify='space-between'>
            <Badge color='#008200' text={<FormattedMessage id='answered_questions' />} />
            <Badge color='#FF0500' text={<FormattedMessage id='remaining_questions' />} className='mx-3' />
            <Badge color='#E4E900' text={<FormattedMessage id='unsaved_questions' />} />
          </Flex>
        </div>
      </Flex>

      <div className="p-5 mb-4 rounded-lg shadow-sm bg-[#FAFAFA]">
        <div className="grid grid-cols-22 gap-1 mt-3">
          {renderQuestionStatus(uniqueAnsweredQuestions, 'bg-green-500', 'text-white')}
          {renderQuestionStatus(uniqueUnsavedQuestions, 'bg-yellow-500', 'text-black')}
          {renderQuestionStatus(remainingQuestions, 'bg-red-500', 'text-white')}
        </div>
      </div>

      {decryptedQuestions.map((question: any, index: number) => (
        <QuestionCard key={index} question={question} index={index} />
      ))}
    </div>
  );
};

export default QuestionTracking;
