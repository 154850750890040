import React from "react";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import ListELearnings from "../../components/eLearning/ListELearnings";

export default function ELearning() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'e_learning'} />} />
      <ListELearnings />
    </>
  );
}


