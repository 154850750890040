import React, { useState } from 'react';
import { DatePicker, Flex } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../../helpers/apiUtils';
import FilterTable from '../../../common/filtersTables/FilterTable';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { deleteData } from '../../../../network/deleteData';
import DeleteModal from '../../../common/DeleteModal';
import { StoreData } from '../../../../network/storeDate';
import AddModal from '../../../common/AddModal';
import UpdateModal from '../../../common/UpdateModal';
import { DeleteOutlined, FieldTimeOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import PaginationComponentDynmicData from '../../../common/PaginationComponentDynmicData';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

interface InfoProps {
  assessmentData: any;
}

const ExamSchedule: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "assessment_exam_schedule",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "assessment_exam_schedule",
    type: "get",
    assessment_type_id: assessmentData.id,
  };

  const { data: listExams, isLoading, isFetching } = useQuery(
    [queryKeys.listExams, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExams);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['status', 'scheduled_at', 'exam_configuration'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const formFields = [
    {
      name: "scheduled_at", label: <FormattedMessage id={'scheduled_at'} />,
      rules: [{ required: true, message: 'Missing scheduled_at' }],
      input:
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
        />
      ,
      colSize: 24
    }
  ];

  const updateFormFields = [
    {
      name: "scheduled_at", label: <FormattedMessage id={'scheduled_at'} />,
      rules: [{ required: true, message: 'Missing scheduled_at' }],
      input:
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
        />
      ,
      colSize: 24
    }
  ];

  const renderCardData = (item: any) => (
    <div>
      <Flex className='mb-3'>
        <div style={{ color: "rgba(0, 0, 0, 0.85)" }} className='text-[16px]'>
          <FieldTimeOutlined />
        </div>
        <div style={{ color: "rgba(0, 0, 0, 0.45)" }} className='mx-2 text-[16px]' >
          {item.scheduled_at}
        </div>
      </Flex>
    </div>
  );

  const renderActions = (item: any) => (
    [
      <Link to="/assessment/exam/data" className='text-gray-400' state={{ exam_configuration: item }}><EyeOutlined /></Link>,
      <EditOutlined key="edit" onClick={() => showModal('update', item.id)} />,
      <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />
    ]
  );

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='exam_schedule' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_exam_scheduling' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
        isSearch={false}
        isUpload={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'exam_schedule'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        queryKey={queryKeys.listExams}
        assessmentData={2}
        mode='assessment_exam_schedule'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'exam_schedule'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        selectedRowId={selectedRowId}
        initialValues={{
          scheduled_at: dayjs(decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.scheduled_at)
        }}
        formFields={updateFormFields}
        queryKey={queryKeys.listExams}
        mode='assessment_exam_schedule'
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        queryKey={queryKeys.listExams}
        confirmationHeader={<FormattedMessage id={'exam_schedule'} />}
        onClose={closeModal}
      />

      <PaginationComponentDynmicData
        gutterNumber={13}
        spanNumber={6}
        badge
        isFetching={isFetching}
        isLoading={isLoading}
        decryptedData={decryptedData}
        actions={renderActions}
        data={renderCardData}
      />
    </>
  );
};

export default ExamSchedule;