import React from 'react';
import { Button, Card, Col, Flex, message, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { decryptData } from '../../../helpers/apiUtils';
import { useMutation } from '@tanstack/react-query';
import queryClient from '../../../services/react-query/queryClient';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { addELearning } from '../../../network/storeDate';

interface BasicInfoProps {
  exam_scheduling_data: any;
}

const BasicInfo: React.FC<BasicInfoProps> = ({ exam_scheduling_data }) => {
  const formatInstruction = (instruction: string) => {
    const formattedLines = instruction.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));

    return <>{formattedLines}</>;
  };
  const { mutate: onRunImmediate, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.exam]);
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const onFinish = async () => {
    let form_data = new FormData();
    form_data.append('mode', "examinee");
    form_data.append('type', "run_immediate");
    form_data.append('exam_schedule_id', exam_scheduling_data?.id);

    onRunImmediate(form_data);
  };

  return (
    <div className='mt-5'>
      <Flex justify='end' className='mb-4'>
        <Button onClick={onFinish} loading={isLoading}>
          <FormattedMessage id={'run_immediately'} />
        </Button>
      </Flex>

      <Card className='bg-[#FAFAFA] mb-4'>
        <Row justify={"space-between"}>
          <Col>
            <Row gutter={15}>
              <Col>
                <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'exam_date'} />:</div>
              </Col>
              <Col>
                <div className='text-[16px] text-[#515B68]'>{exam_scheduling_data?.scheduled_at}</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col>
            <Row gutter={15}>
              <Col>
                <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'exam_status'} />:</div>
              </Col>
              <Col>
                <div className='text-[16px] text-[#515B68]'>{exam_scheduling_data?.status}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className='xl:w-[200px] bg-[#F0F0F0] mb-4'>
        <Col className='py-3 xl:px-2'>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0203571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.2057 3.07589C17.8749 3.0067 17.8749 2.89286 17.2057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
          </svg>
        </Col>
        <Col className='py-3'>
          <FormattedMessage id={'exam_configuration'} />
        </Col>
      </Row>
      <Card className='bg-[#FAFAFA] border-none mb-4'>
        <Row className='mb-4'>
          <Col xl={12}>
            <Row gutter={20}>
              <Col className='text-[#2D3A49] text-[16px] text-center font-extrabold'><FormattedMessage id={'name'} /></Col>
              <Col className='text-[16px] text-[#515B68]  text-center'>{decryptData(exam_scheduling_data?.exam_configuration?.exam_title)}</Col>
            </Row>
          </Col>
          <Col xl={12}>
            <Row gutter={20}>
              <Col className='text-[#2D3A49] text-[16px] text-center font-extrabold'><FormattedMessage id={'exam_duration'} /></Col>
              <Col className='text-[16px] text-[#515B68] text-center'>{exam_scheduling_data?.exam_configuration?.exam_duration}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Row gutter={20}>
              <Col className='text-[#2D3A49] text-[16px] text-center font-extrabold'><FormattedMessage id={'certificate'} /></Col>
              <Col className='text-[16px] text-[#515B68]  text-center'>{exam_scheduling_data?.exam_configuration?.certificate?.name}</Col>
            </Row>
          </Col>
          <Col xl={12}>
            <Row gutter={20}>
              <Col className='text-[#2D3A49] text-[16px] text-center font-extrabold'><FormattedMessage id={'number_of_questions'} /></Col>
              <Col className='text-[16px] text-[#515B68] text-center'>{exam_scheduling_data?.exam_configuration?.number_of_questions}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card className='bg-[#FAFAFA] border-none mb-4'>
        <div className='text-[#2D3A49] text-[16px] font-extrabold'><FormattedMessage id={'intsruction'} />:</div>
        <div className='text-[16px] text-[#515B68]'>{formatInstruction(decodeURIComponent(decryptData(exam_scheduling_data?.exam_configuration?.exam_notes)))}</div>

        <div className='text-[16px] text-[#515B68]'>{ }</div>
      </Card>
    </div>
  );
}

export default BasicInfo;