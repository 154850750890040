import { Empty, Flex, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

type Props = {
  columns: ColumnsType<any>;
  data: any;
  scroll?: any;
  expandableData?: any;
  isLoading?: boolean;
  isFetching?: boolean;
};

const CustomExpandedTable = ({ isFetching,columns, data, scroll, expandableData, isLoading }: Props) => {
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const tableClassName = data && data.length > 0 ? "dataPresent" : "dataEmpty";

  return (
    <div className={`${currentLang === "ar" ? "customExpandedTableAr" : "customExpandedTableEn"} ${tableClassName}`}>
      {isLoading || isFetching ? (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      ) : data && data.length > 0 ? (
        <Table columns={columns} dataSource={data || []} rowClassName={() => "customTableRow"} scroll={scroll} expandable={expandableData} locale={{ emptyText: <Empty description={<FormattedMessage id='no_data' />} /> }} />

      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </div>
  );
};

export default CustomExpandedTable;
