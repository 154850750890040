import React, { useState } from 'react';
import { Row, Col, Card, Empty, Pagination, Badge, Flex, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

interface Props {
  decryptedData: any;
  actions: any;
  gutterNumber: any;
  title?: any;
  extra?: any;
  badge?: any;
  data: (item: any) => React.ReactNode;
  spanNumber: any;
  isLoading?: boolean;
  isFetching?: boolean;
}

const PaginationComponentDynmicData: React.FC<Props> = ({ isFetching, isLoading, badge, decryptedData, actions, gutterNumber, spanNumber, data, extra, title }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {isLoading || isFetching ? (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      ) : decryptedData && decryptedData.length > 0 ? (
        <>
          <Row gutter={gutterNumber} >
            {currentItems.map((item: any) => (
              <Col key={item.id} className='mb-3' xl={spanNumber} sm={24} xxl={8} lg={8} md={12}>
                {badge ? (
                  <Badge.Ribbon text={item.status}>
                    <Card
                      className="rounded-sm"
                      actions={actions(item)}
                      title={title ? title(item) : undefined}
                      extra={extra ? extra(item) : undefined}
                    >
                      <div>{data(item)}</div>
                    </Card>
                  </Badge.Ribbon>
                ) : (
                  <Card
                    className="rounded-sm"
                    actions={actions(item)}
                    title={title ? title(item) : undefined}
                    extra={extra ? extra(item) : undefined}
                  >
                    <div style={{ height: "90px" }}>{data(item)}</div>
                  </Card>
                )}
              </Col>
            ))}
          </Row>
          <Flex justify='end'>
            <Pagination
              current={currentPage}
              total={decryptedData?.length}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              className='mt-1'
            />
          </Flex>
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default PaginationComponentDynmicData;
