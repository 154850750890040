import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { decodeResponse } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddLevel from './AddLevel';
import UpdateLevel from './UpdateLevel';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import { Link } from 'react-router-dom';

interface LevelProps {
  certificateData: any;
}

const ListLevels: React.FC<LevelProps> = ({ certificateData }) => {
  const [editLevelData, setEditLevelData] = useState(null);
  const [selectedLevelId, setSelectedLevelId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | 'delete' | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 12;

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedLevelId(id ?? null);
    setEditLevelData(data ?? null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteLevelParams = {
    mode: 'level',
    type: 'delete',
    id: selectedLevelId,
  };

  const fetchLevelsParams = {
    mode: 'level',
    type: 'get',
    certificate_id: certificateData.id,
  };

  const { data: levelsData, isLoading, isFetching } = useQuery(
    [queryKeys.listLevels, searchTerm],
    () => FetchData({
      ...fetchLevelsParams,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedLevelsData = decodeResponse(levelsData);
  const currentItems: any[] = decodedLevelsData?.data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const breadcrumbItems = [
    {
      title: <FormattedMessage id='certificate' />,
      href: '/certificate',
    },
    {
      title: <FormattedMessage id='level' />,
      href: '',
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderLevels = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decodedLevelsData?.data || decodedLevelsData?.data.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        <Row
          gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 15 }}
        >
          {currentItems?.map((level: any) => (
            <Col className='mb-4' key={level.id} xl={6} lg={6} md={12} sm={12} xs={24}>
              <Card
                title={level?.name}
                actions={[
                  <Link
                    to='/certificate/chapter'
                    className='text-[#ED1D23]'
                    state={{ level_data: level }}
                  >
                    <EyeOutlined />
                  </Link>,
                  <EditOutlined key='edit' onClick={() => showModal('update', level.id, level)} />,
                  <DeleteOutlined key='delete' style={{ color: 'red' }} onClick={() => showModal('delete', level.id)} />,
                ]}
              >
                <Flex align='center'>
                  <img alt='example' src={level.media} className='w-full' />
                </Flex>
              </Card>
            </Col>
          ))}
        </Row>
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={decodedLevelsData?.data?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <FilterTable
        isFilter={false}
        items={breadcrumbItems}
        onSearch={(value) => setSearchTerm(value)}
        buttonText={<FormattedMessage id='add_level' />}
        tabName={<FormattedMessage id='level' />}
        onClick={() => showModal('add')}
        isUpload={false}
      />

      <UploadFileModal
        visible={isModalVisible && modalAction === 'upload'}
        onClose={closeModal}
        mode={'assessment_advice'}
        confirmationHeader={''}
        successMessage={''}
        queryKey={queryKeys.listAdvice}
      />

      <AddLevel
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
        certificateData={certificateData}
      />

      <UpdateLevel
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        levelData={editLevelData}
        certificateData={certificateData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteLevelParams}
        confirmationHeader={<FormattedMessage id='level' />}
        queryKey={queryKeys.listLevels}
        onClose={closeModal}
      />

      {renderLevels()}
    </>
  );
};

export default ListLevels;