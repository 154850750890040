import { FC } from "react";
import React from "react";
import { Flex } from "antd";

interface CustomHeaderProps {
  title: any;
}

const CustomHeader: FC<CustomHeaderProps> = ({
  title,
}) => {
  return (
    <Flex>
      <div className="pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 31" fill="none">
          <path d="M28.1253 15.497H26.2503C26.103 15.497 25.9824 15.6175 25.9824 15.7648V26.4791H4.01814V4.51481H14.7324C14.8797 4.51481 15.0003 4.39427 15.0003 4.24695V2.37195C15.0003 2.22463 14.8797 2.1041 14.7324 2.1041H2.67885C2.08622 2.1041 1.60742 2.58289 1.60742 3.17552V27.8184C1.60742 28.411 2.08622 28.8898 2.67885 28.8898H27.3217C27.9143 28.8898 28.3931 28.411 28.3931 27.8184V15.7648C28.3931 15.6175 28.2726 15.497 28.1253 15.497Z" fill="#0E103A" />
          <path d="M9.7735 16.2637L9.70988 20.2447C9.70653 20.5427 9.9476 20.7871 10.2456 20.7871H10.259L14.2099 20.69C14.2768 20.6867 14.3438 20.6599 14.3907 20.613L28.3159 6.71793C28.4197 6.61414 28.4197 6.44338 28.3159 6.33959L24.1541 2.1811C24.1005 2.12753 24.0335 2.1041 23.9632 2.1041C23.8929 2.1041 23.826 2.13088 23.7724 2.1811L9.85051 16.0762C9.80215 16.1268 9.77464 16.1937 9.7735 16.2637ZM11.8996 17.0539L23.9632 5.01704L25.4766 6.52709L13.4063 18.5706L11.8762 18.6074L11.8996 17.0539Z" fill="#0E103A" />
        </svg>
      </div>
      <div className="text-[23px]">{title}</div>
    </Flex>
  );
};

export default CustomHeader;
