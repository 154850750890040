import { ConfigProvider, App as AntApp } from "antd";
import "./styles/globals.scss";
import RoutesWrapper from "./Routes";
import dayjs from "dayjs";
import uts from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import "react-phone-number-input/style.css";
import QueryProvider from "./services/react-query";
import { IntlProvider } from "react-intl";
import LangEn from "./lang/en.json";
import LangAr from "./lang/ar.json";
import { useSelector } from "react-redux";

dayjs.extend(duration);
dayjs.extend(uts);

function App() {
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const validateMessages = {
    required: `الرجاء إدخال بيانات الحقل`,
  };

  return (
    <QueryProvider>
      <IntlProvider
        locale={currentLang || "en"}
        defaultLocale="en"
        messages={currentLang === "en" ? LangEn : LangAr}
      >
        <ConfigProvider
          form={{ validateMessages, requiredMark: true }}
          virtual
          direction={currentLang === "ar" ? "rtl" : "ltr"}
          // locale={isArabicLang ? arEG : enUS}
          theme={{
            token: {
              fontFamily: "Cairo, sans-serif",
              // colorPrimary: "#0E18AB",
              // colorInfo: "#faad14",
              // borderRadius: 8,
              // colorText: "#212121",
              // fontSize: 16,
              // controlHeight: 42,
              // colorBtnColor: "#fff",
              // secBtnBg: "#F8F5FF",
              // secColor: "#8A4CF5",
              // defaultBg: "#EEF0FF",
              // defaultColor: "#4F5062",
              // defaultBorderColor: "#B0B0C0",
              // thirdColor: "#59A6ED",
              // contentFontSize: 16,
              // Accent: "#EBF2FA",
              // line: "#CFD8E3",
              // grayTxt: "#595959",
              // linearBg: "linear-gradient(270deg, #8A4CF5 0%, #595FBC 100%)",
              // secBtnBorderColor: "#C7BBF9",
              // colorPrimaryBgHover: "#595FBC",
              // colorPrimaryHover: "#595FBC",
              // colorTextDescription: "#0E18AB",
              // colorPrimaryActive: "#0E18AB",
              // colorPrimaryText: "#0E18AB",
              // colorPrimaryTextActive: "#0E18AB",
              // colorPrimaryTextHover: "#0E18AB",
              // colorLink: "#0E18AB",
              // colorLinkHover: "#0E18AB",
              // colorSuccess: "#238709",
              // colorSuccessBg: "#E9FFDA",
              // colorWarning: "#ff9d00",
              // colorWarningBg: "#FFF4E2",
              // colorError: "#cf2110",
              // colorErrorBg: "#FFE7E4",
              // colorTextBase: "#000",
              // colorFillQuaternary: "#ebf2fa",
              // colorFillSecondary: "#ebf2fa",
              // colorPrimaryBg: "#ede8ff",
              // fontSizeXL: 18,
              // fontSizeHeading5: 16,
              // fontSizeHeading4: 18,
              // fontSizeHeading3: 22,
              // fontSizeHeading2: 25,
              // fontSizeHeading1: 32,
              // marginMD: 24,
              // wireframe: false,
              // marginLG: 32,
              // marginXL: 40,
              // paddingMD: 24,
              // paddingLG: 32,
              // paddingXL: 40,
              // borderRadiusSM: 4,
              // borderRadiusLG: 12,
              // borderRadiusXS: 4,
              // colorBgLayout: "#F0F1F2",
              // colorBgContainer: "#ffffff",
              // colorBgElevated: "#ffffff",
              // boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1);",
              // boxShadowSecondary: "0px 0px 24px rgba(0, 0, 0, 0.1);",
              // colorPrimaryBorder: "#0E18AB",
            },
            components: {
              Radio: {
                colorPrimary: "#f00",
              },
              Checkbox: {
                borderRadius: 4,
              },
              Select: {},
              Form: {
                // size: 'large',
                size: 50,
              },
            },
          }}
        >
          <AntApp>
            <RoutesWrapper />
          </AntApp>
        </ConfigProvider>
      </IntlProvider>
    </QueryProvider>
  );
}

export default App;
