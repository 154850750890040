import React, { useState } from 'react';
import { Col, Input } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptData, decryptItem } from '../../../helpers/apiUtils';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import UpdateModal from '../../common/UpdateModal';
import AddModal from '../../common/AddModal';
import { StoreData } from '../../../network/storeDate';
import UploadFileModal from '../../common/UploadFile';
import { FormattedMessage } from "react-intl";
import FilterTable from '../../common/filtersTables/FilterTable';
import PaginationComponent from '../../common/PaginationComponent';
import { useNavigate } from 'react-router-dom';

interface InfoProps {
  categoryData: any;
}
const SubCategory: React.FC<InfoProps> = ({ categoryData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "assessment_sub_category",
    type: "get",
    category: categoryData.id
  };

  const { data: listCategories, isLoading, isFetching } = useQuery(
    [queryKeys.listCategories, categoryData.id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const deleteValue = {
    mode: "assessment_sub_category",
    type: "delete",
    id: selectedRowId
  };

  const decodedResponse = decodeResponse(listCategories);
  const decryptedData = decodedResponse?.data.map(decryptItem);

  const subCategoryFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter category name" />, colSize: 24 },
  ];

  const updateSubCategoryFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter category name" />, colSize: 24 },
  ];

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <span onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
        <FormattedMessage id='categories' />
      </span>,
    },
    {
      title: <FormattedMessage id='attribute' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_attribute' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(decryptData(value))}
        isFilter={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === 'add'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'attribute'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={subCategoryFormFields}
        queryKey={queryKeys.listCategories}
        assessmentData={categoryData.id}
        mode='assessment_sub_category'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'attribute'} />}
          </span>
        }
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name
        }}
        formFields={updateSubCategoryFormFields}
        queryKey={queryKeys.listCategories}
        mode='assessment_sub_category'
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        queryKey={queryKeys.listCategories}
        onClose={closeModal}
        confirmationHeader={<FormattedMessage id={'attribute'} />}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_attribute"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listCategories} />
      <PaginationComponent
        isLoading={isLoading}
        isFetching={isFetching}
        decryptedData={decryptedData}
        actions={(item: { id: React.Key; }) => (
          <>
            <Col><EditOutlined key="edit" onClick={() => showModal('update', item.id)} /></Col>
            <Col><DeleteOutlined key="delete" className='text-red-400' onClick={() => showModal('delete', item.id)} /></Col>
          </>
        )} gutterNumber={16} spanNumber={8} />
    </>
  );
};

export default SubCategory;