import { Button, Card, Col, Flex, Form, Input, Row, Select, Switch, Typography, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { StoreData } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse } from '../../../helpers/apiUtils';
import { MinusCircleOutlined, PlusOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import DeleteSVG from '../../../assets/common/DeleteSVG';
import CustomHeader from '../../common/CustomHeader';
import { FormattedMessage } from 'react-intl';
import { questionDifficulty } from '../../../enum/questionDifficulty';
import { roleLabelsAr, roleLabelsEn } from '../../../enum/questionType';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function AddQuestionsBank() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const type = currentLang === 'ar' ? roleLabelsAr : roleLabelsEn;

  const { mutate: onAddExam, isLoading } = useMutation(StoreData, {
    onSuccess: (res) => {
      console.log("Add Exam response:", res);
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listQuestionsBank]);
      navigate("/questions/bank");
    },
    onError: (error) => {
      console.log("Add Category error:", error);
      message.error("حاول مرة اخرى");
    },
  });

  const onFinish = async (values: any) => {
    const processedQuestions = values.questions.map((question: any) => {
      const switch_value = question.switch;
      if (question.type === 'mcq') {
        let answers = question.answers.map((answer: any) => ({
          ...answer,
          is_correct: question.right_answers?.some((right: any) => right.answer === answer.answer),
        }));

        question.right_answers.forEach((right: any) => {
          if (!answers?.some((answer: any) => answer.answer === right.answer)) {
            answers.push({
              answer: right.answer,
              is_correct: true,
            });
          }
        });

        return {
          ...question,
          answers,
        };
      } else if (question.type === 'true_false') {
        const answers = [
          { answer: 'true', is_correct: switch_value },
          { answer: 'false', is_correct: !switch_value },
        ];

        return {
          ...question,
          answers,
        };
      }
      return question;
    });

    const data = {
      mode: "question_bank",
      type: "store",
      questions: processedQuestions,
    };

    onAddExam(data);
  };

  const valueCertificate = {
    mode: "certificate",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, valueCertificate.language_id],
    () => FetchData(valueCertificate)
  );

  const valueChapter = {
    mode: "chapter",
    type: "get",
    level_id: selectedLevel,
  };

  const valueLevel = {
    mode: "level",
    type: "get",
    certificate_id: selectedCertificate,
  };

  const { data: listChapters } = useQuery(
    [queryKeys.listChapters, selectedLevel],
    () => FetchData(valueChapter),
    {
      enabled: !!selectedLevel,
    }
  );

  const { data: listLevels } = useQuery(
    [queryKeys.listLevels, selectedCertificate],
    () => FetchData(valueLevel),
    {
      enabled: !!selectedCertificate,
    }
  );

  const onClose = () => {
    navigate("/questions/bank");
  };

  const decodedResponse = decodeResponse(listCertificates);
  const decodedResponseExamChapters = decodeResponse(listChapters);
  const decodedResponseLevels = decodeResponse(listLevels);
  const formData = Form.useWatch('questions', form) || [];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'add_questions_bank'} />} />

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        className="form-style mt-8 p-1"
        form={form}
        initialValues={{
          questions: [{
            type: "mcq",
            answers: [{}]
          }]
        }}
      >

        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                const question_type = formData[index]?.type;

                return (
                  <Card className='p-3 mb-2' style={{ background: "rgba(245, 245, 245, 0.75)" }}>
                    <Flex justify="end">
                      <Button
                        type="text"
                        htmlType="button"
                        onClick={() => remove(name)}
                      >
                        <DeleteSVG />
                      </Button>
                    </Flex>
                    <Row gutter={15}>
                      <Col xl={12}>
                        <Form.Item
                          name={[name, 'certificate_id']}
                          label={<FormattedMessage id={'certificate'} />}
                          required={false}
                          className="form-style w-full"
                          rules={[
                            { required: true, message: 'certificate is required' },
                          ]}
                        >
                          <Select
                            placeholder="Choces Certificate"
                            options={decodedResponse?.data?.map((item: { id: any; name: any; }) => ({
                              value: item?.id,
                              label: item?.name,
                            }))}
                            onChange={(value) => setSelectedCertificate(value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={12}>
                        <Form.Item
                          {...restField}
                          label={<FormattedMessage id={'question_type'} />}
                          required={false}
                          name={[name, 'type']}
                          rules={[{ required: true, message: 'Missing Type' }]}
                        >
                          <Select
                            placeholder="Choces Question Type"
                            options={type?.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                      <Col xl={8}>
                        <Form.Item
                          {...restField}
                          label={<FormattedMessage id={'difficulty'} />}
                          required={false}
                          name={[name, 'difficulty']}
                          rules={[{ required: true, message: 'Missing Difficulty' }]}
                        >
                          <Select
                            placeholder="Choces Question Level"
                            options={questionDifficulty?.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8}>
                        <Form.Item
                          {...restField}
                          label={<FormattedMessage id={'level'} />}
                          required={false}
                          name={[name, 'level_id']}
                          rules={[{ required: true, message: 'Missing Level' }]}
                        >
                          <Select
                            placeholder="Choces Question Level"
                            options={decodedResponseLevels?.data?.map((item: { id: any; name: any; }) => ({
                              value: item?.id,
                              label: item?.name,
                            }))}
                            onChange={(value) => setSelectedLevel(value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8}>
                        <Form.Item
                          {...restField}
                          label={<FormattedMessage id={'chapter'} />}
                          required={false}
                          name={[name, 'chapter_id']}
                          rules={[{ required: true, message: 'Missing Chapter' }]}
                        >
                          <Select
                            placeholder="Choces Chapter"
                            options={decodedResponseExamChapters?.data?.map((item: { id: any; name: any; }) => ({
                              value: item?.id,
                              label: item?.name,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                      <Col xl={24}>
                        <Form.Item
                          {...restField}
                          label={<FormattedMessage id={'Question'} />}
                          required={false}
                          name={[name, 'question']}
                          rules={[{ required: true, message: 'Missing Question' }]}
                        >
                          <TextArea rows={3} placeholder="Enter Question" />
                        </Form.Item>
                      </Col>
                    </Row>


                    {question_type === 'mcq' && question_type && (
                      <>
                        <Typography.Title level={5} type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'answers'} /></Typography.Title>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }} className="w-full">
                          <Form.List name={[name, 'answers']}>
                            {(answerFields, { add: addAnswer, remove: removeAnswer }) => (
                              <>
                                {answerFields.map(({ key: answerKey, name: answerName, ...answerRestField }) => (
                                  <Col span={12} key={answerKey}>
                                    <Row gutter={20}>
                                      <Col span={22}>
                                        <Form.Item
                                          required={false}
                                          {...answerRestField}
                                          name={[answerName, 'answer']}
                                          rules={[{ required: true, message: 'Missing Answer' }]}
                                        >
                                          <Input placeholder="Enter Answer" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={2} className='mt-2'>
                                        <MinusCircleOutlined onClick={() => removeAnswer(answerName)} />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => addAnswer()}
                                    block
                                    icon={<PlusOutlined />}
                                    disabled={answerFields.length >= 3}
                                  >
                                    Add Answer
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Row>
                        <Typography.Title level={5} type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'right_ans'} /></Typography.Title>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }} className="w-full">
                          <Form.List name={[name, 'right_answers']}>
                            {(answerFields, { add: addAnswer, remove: removeAnswer }) => (
                              <>
                                {answerFields.map(({ key: answerKey, name: answerName, ...answerRestField }) => (
                                  <Col span={12} key={answerKey}>
                                    <Row gutter={20}>
                                      <Col span={22}>
                                        <Form.Item
                                          required={false}
                                          {...answerRestField}
                                          name={[answerName, 'answer']}
                                          rules={[{ required: true, message: 'Missing Right Answer' }]}
                                        >
                                          <Input placeholder="Enter Right Answer" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={2} className='mt-2'>
                                        <MinusCircleOutlined onClick={() => removeAnswer(answerName)} />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => addAnswer()}
                                    block
                                    icon={<PlusOutlined />}
                                    disabled={answerFields.length >= 3}
                                  >
                                    Add Answer
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Row>
                      </>
                    )}

                    {question_type !== "mcq" && (
                      <Row gutter={24}>
                        <Col>
                          <Typography.Title level={5} className='' type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'right_ans'} /></Typography.Title>
                        </Col>
                        <Col span={12} className='mt-6'>
                          <Form.Item
                            {...restField}
                            name={[name, 'switch']}
                            valuePropName="checked"
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Card>
                );
              })}
              <Form.Item>
                <Button style={{ width: "200px" }} className='mt-3' onClick={() => add()} block icon={<PlusOutlined />}>
                  <FormattedMessage id={'add_question'} />
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
            onClick={onClose}
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </>

  );
}

export default AddQuestionsBank;


