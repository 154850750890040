import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import Examinee from "../../components/exam/examMonitoring/examinee/Examinee";

export default function ExamMonitoringData() {
  const location = useLocation();
  const data = location?.state.exam_configuration;

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'exam'} />} />
      <Examinee exam_scheduling_data={data} />
    </>

  );
}

