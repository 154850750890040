import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import EnvelopeSVG from '../../../assets/teamMember/EnvelopeSVG';
import PhoneSVG from '../../../assets/teamMember/PhoneSVG';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import UpdateAccreditationCenter from './UpdateAccreditationCenter';
import AddAccreditationCenter from './AddAccreditationCenter';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';


function AccreditationCenter() {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [editAccreditationCenterData, setEditAccreditationCenterData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditAccreditationCenterData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "accreditation_center",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "accreditation_center",
    type: "get"
  };

  const { data: listAccreditationCenter, isLoading, isFetching } = useQuery(
    [queryKeys.listAccreditationCenter, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listAccreditationCenter);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['name', 'country', 'city', 'quality_manual', 'tax_registration', 'commercial_registration', 'website', 'accreditation', 'license', 'contact_person', 'contact_title', 'status'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const renderAccreditationCenter = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decryptedData || decryptedData?.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    const currentItems: any[] = decryptedData?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }}>
          {currentItems?.map((item: any) =>
            <Col className='mb-4' xl={6} lg={6} md={12} sm={12} xs={24}>
              <Card
                className='rounded-sm'
                title={item?.name} extra={<Link to="/accreditation/center/data" className='text-[#ED1D23]' state={{ accreditation_center_data: item }}><FormattedMessage id={'more'} /></Link>}
                actions={[
                  <div><EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} /> </div>,
                  <div><DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} /> </div>,
                ]}
              >
                <Flex >
                  <div className='mt-[6px]'>
                    <EnvelopeSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.email}
                  </div>
                </Flex>
                <Flex >
                  <div className='mt-[6px]'>
                    <PhoneSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.phone}
                  </div>
                </Flex>
              </Card>
            </Col>
          )}
        </Row>
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={decryptedData?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <FilterTable
        onSearch={(value) => setSearchTerm(value)}
        buttonText={<FormattedMessage id={'add_accreditation_center'} />}
        items={[{ title: <FormattedMessage id='accreditation_centers' /> }]}
        onClick={() => showModal('add')}
        isUpload={false}
        isFilter={false}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />
      <AddAccreditationCenter
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <UpdateAccreditationCenter
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        editAccreditationCenterData={editAccreditationCenterData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'accreditation_centers'} />}
        queryKey={queryKeys.listAccreditationCenter}
        onClose={closeModal}
      />

      {renderAccreditationCenter()}

    </>
  );
}

export default AccreditationCenter;
