import React from "react";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import ListCertificates from "../../components/certificate/ListCertificates";

export default function Certificate() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'certificate'} />} />
      <ListCertificates />
    </>
  );
}


