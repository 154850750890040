import React from "react";
import { Radar } from "react-chartjs-2";

export default function TopPurchasedPie() {
  const data = {
    labels: [
      "IBDL Essential",
      "IBDL Professional",
      "IBDL Master",
      "Certified Educator",
      "Certified Educator",
      "Certified Educator",
      "Certified Educator",
    ],
    datasets: [{
      label: 'IBDL Essential',
      data: [65, 59, 90, 81, 56, 55, 40],
      fill: true,
      backgroundColor: '#F0F0F0',
      borderColor: '#0E103A',
      pointBackgroundColor: '#0E103A',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255, 99, 132)'
    }, {
      label: 'IBDL Professional',
      data: [28, 48, 40, 19, 96, 27, 100],
      fill: true,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgb(54, 162, 235)',
      pointBackgroundColor: 'rgb(54, 162, 235)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(54, 162, 235)'
    }]
  };

  const options = {
    elements: {
      line: {
        borderWidth: 3
      }
    }
  };

  return (
    <div dir="rtl" style={{ height: "18rem" }}>
      <Radar data={data} options={options} />
    </div>
  );
}
