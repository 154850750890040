import { useMutation } from "@tanstack/react-query";
import { Button, Flex, Form } from "antd";
import { Input, message } from "antd";
import { FC } from "react";
import { authLogin } from "../../network/auth";
import { useDispatch } from "react-redux";
import { setUserData } from "../../services/store/slices/user";
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

interface LoginFormProps { }
const LoginForm: FC<LoginFormProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(authLogin); 

  const onFinish = (values: { email: string; password: string }) => {
    const authBody = {
      mode: "auth",
      type: "login",
      email: values.email,
      password: values.password,
    };

    mutate(authBody, {
      onSuccess: (res: any) => {
        console.log(res);
        if (res?.code !== "ERR_BAD_REQUEST") {
          if (res?.data?.data?.token) {
            dispatch(setUserData(res?.data?.data));
            localStorage.setItem("token", res?.data?.data?.token);
            message.success("Login successful.");
            navigate("/");
          }
        } else {
          message.error("The email or mobile number is not valid.");
        }
      },
      onError: () => {
        message.error("The email or mobile number is not valid.");
      },
    });
  };


  return (
    <Form layout="vertical" onFinish={onFinish} className="xl:w-[400px] lg:w-[400px] md:w-[400px]">
      <Form.Item
        required={false}
        colon={false}
        hasFeedback
        name={"email"}
        rules={[
          {
            required: true,
            message: "Enter Email",
          }
        ]}
      >
        <Input prefix={<UserOutlined className="text-[#EC1C23]" />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        required={false}
        colon={false}
        hasFeedback
        name={"password"}
        rules={[
          {
            required: true,
            message: "Enter Password",
          },
          {
            min: 5,
            message: "The password must be at least 8 characters or numbers.",
          },
        ]}
      >
        <Input.Password prefix={<LockOutlined className="text-[#EC1C23]" />} placeholder="Password" />
      </Form.Item>
      <Flex>
        <Form.Item>
          <Button
            htmlType="submit"
            type="text"
            className="bg-[#e11119] text-white h-[40px] w-[114px]"
            loading={isLoading}
          >
            Login
          </Button>
        </Form.Item>
      </Flex>

    </Form>
  );
};

export default LoginForm;
