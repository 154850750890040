import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptData, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { deleteData } from '../../../network/deleteData';
import DeleteModal from '../../common/DeleteModal';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import UpdateModal from '../../common/UpdateModal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import { useSelector } from 'react-redux';
import { lang } from '../../../enum/lang';
import PaginationComponentDynmicData from '../../common/PaginationComponentDynmicData';

interface InfoProps {
  assessmentData: any;
}

const Statement: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(null);

  const handleLanguageChange = (value: number) => {
    setSelectedLanguageId(value);
  };

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "assessment_statement",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "assessment_statement",
    type: "get",
    assessment_type_id: assessmentData.id,
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const categoriesValues = {
    mode: "assessment_sub_category",
    type: "get",
    category: selectedLanguageId === 1 ? 1 : selectedLanguageId === 2 ? 2 : null
  };

  const { data: listStatements, isLoading, isFetching } = useQuery(
    [queryKeys.listStatement, searchTerm, values.language_id],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listStatements);
  const decryptedData = (decodedResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['sub_assessment_category'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const { data: listCategories } = useQuery(
    [queryKeys.listCategories, categoriesValues, selectedLanguageId],
    () => FetchData(categoriesValues),
    {
      keepPreviousData: true,
    }
  );

  const decodedCategoriesResponse = decodeResponse(listCategories);
  const decryptedCategoriesData = decodedCategoriesResponse?.data.map(decryptItem);

  const formFields = [
    {
      name: "language_id", label: <FormattedMessage id={'language'} />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        placeholder="choces language"
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        onChange={handleLanguageChange}
      />
      , colSize: 12
    },
    {
      name: "sub_assessment_category_id", label: <FormattedMessage id='category' />, rules: [{ required: true, message: "Assessment Category is required" }], input: <Select
        placeholder="category"
        options={decryptedCategoriesData?.map((item: any) => ({
          value: item?.id,
          label: item?.name
        }))}
      />, colSize: 12
    },
    {
      name: "statement", label: <FormattedMessage id='statement' />, input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
        placeholder="Enter Statement"
      />
      , colSize: 24
    },
  ];

  const updateFormFields = [
    {
      name: "language_id", label: <FormattedMessage id={'language'} />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        placeholder="choces language"
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        onChange={handleLanguageChange}
      />
      , colSize: 12
    },
    {
      name: "sub_assessment_category_id", label: <FormattedMessage id='category' />, rules: [{ required: true, message: "Assessment Category is required" }], input: <Select
        placeholder="category"
        options={decryptedCategoriesData?.map((item: any) => ({
          value: item?.id,
          label: item?.name
        }))}
      />, colSize: 12
    },
    {
      name: "statement", label: <FormattedMessage id='statement' />, input: <Input.TextArea
        id="outlined-basic"
        rows={3}
        size="large"
        placeholder="Enter Statement"
      />
      , colSize: 24
    },
  ];

  const truncateText = (text: string, maxLength: number) =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  const renderCardData = (item: any) => (
    <div>
      <div>
        <div className='mb-3'>
          <div style={{ color: "rgba(0, 0, 0, 0.85)" }} className='text-[16px]'>
            <FormattedMessage id={'statement'} />
          </div>
          <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            {item.statement}
          </div>
        </div>
        <div>
          <div style={{ color: "rgba(0, 0, 0, 0.85)" }} className='text-[16px]'>
            <FormattedMessage id={'category'} />
          </div>
          <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
            {truncateText(decryptData(item?.sub_assessment_category?.name), 20)}
          </div>
        </div>
      </div>
    </div>
  );

  const renderActions = (item: any) => (
    [
      <EditOutlined key="edit" onClick={() => showModal('update', item.id)} />,
      <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />
    ]
  );

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='statements' />,
    }
  ];

  return (
    <>
      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_statement"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listStatement} />
      <FilterTable
        buttonText={<FormattedMessage id='add_statement' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
        isAdded={decryptedData?.length === 56 ? false : true}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'statement'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        queryKey={queryKeys.listStatement}
        assessmentData={assessmentData.id}
        mode='assessment_statement'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'statement'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        selectedRowId={selectedRowId}
        initialValues={{
          language_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.language_id,
          statement: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.statement,
          sub_assessment_category_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.sub_assessment_category_id,
        }}
        formFields={updateFormFields}
        queryKey={queryKeys.listStatement}
        mode='assessment_statement'
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        queryKey={queryKeys.listStatement}
        confirmationHeader={<FormattedMessage id={'statement'} />}
        onClose={closeModal}
      />

      <PaginationComponentDynmicData
        gutterNumber={13}
        spanNumber={6}
        isLoading={isLoading}
        isFetching={isFetching}
        decryptedData={decryptedData}
        actions={renderActions}
        data={renderCardData}
      />
    </>
  );
};

export default Statement;