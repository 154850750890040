import { FC } from "react";
import React from "react";
import { Button, message, Flex, Modal } from "antd";
import { useMutation } from "@tanstack/react-query";
import queryClient from "../../services/react-query/queryClient";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { FormattedMessage, useIntl } from "react-intl";
import DeleteImg from "../../assets/Delete.png"
interface DeleteModalProps<T> {
    visible: boolean;
    onClose: () => void;
    onDelete: (data: T) => Promise<void>;
    data: T | null;
    confirmationHeader: any;
    queryKey: string;
    optionalDiv?: any | null;
}

const DeleteModal: FC<DeleteModalProps<any>> = ({
    visible,
    onClose,
    onDelete,
    data,
    confirmationHeader,
    queryKey,
}) => {
    const intl = useIntl();
    const successMessage = intl.formatMessage({ id: 'success_update_msg' });

    const { mutate: deleteMutation, isLoading } = useMutation(onDelete, {
        onSuccess: () => {
            onClose();
            queryClient.invalidateQueries([queryKey]);
            message.success(successMessage);
        },
        onError: (error) => {
            message.error('Error deleting');
        },
    });

    const handleDelete = async () => {
        if (data) {
            deleteMutation(data);
        }
    };

    return (
        <Modal
            centered
            open={visible}
            className="customModal"
            footer={[]}
            title={[""]}
            onCancel={onClose}
            width={850}
        >
            <div className="text-center p-5" >
                <div className="my-8">
                    <img src={DeleteImg} alt="" style={{ height: "200px" }} />
                </div>
                <div className="mb-4">
                    <div>
                        <span>
                            <ClockCircleOutlined className="text-[#E30613]" />
                        </span>
                        <span className="px-1" style={{ fontWeight: 500 }}>
                            <FormattedMessage id={'delete_text'} /> {confirmationHeader} <FormattedMessage id={'quationMark'} />
                        </span>
                    </div>
                    <div>
                        <FormattedMessage id={'delete_text2'} />
                    </div>
                </div>

                <Flex gap="middle" align="center" justify="center">
                    <Button
                        type="text"
                        className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
                        key="cancel"
                        onClick={onClose}
                    >
                        <FormattedMessage id='cancel' />
                    </Button>
                    <Button
                        key="ok"
                        className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
                        htmlType="submit"
                        type="text"
                        onClick={handleDelete} loading={isLoading}
                    >
                        <FormattedMessage id='delete' />
                    </Button>
                </Flex>
            </div>
        </Modal>


    );
};

export default DeleteModal;
