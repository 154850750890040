import React from "react";
import AccreditationCenterTable from "../../components/accreditation/center/AccreditationCenter";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";

export default function Accreditation() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'accreditation'} />} />
      <AccreditationCenterTable />
    </>
  );
}


