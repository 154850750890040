import React from "react";
import CorporateTable from "../../components/corporate/CorporateTable";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";

export default function Corporate() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'corporate'} />} />
      <CorporateTable />
    </>
  );
}


