import React, { useState } from 'react';
import { Col } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { decodeResponse } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddChapter from './AddChapter';
import UpdateChapter from './UpdateChapter';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import PaginationComponent from '../../common/PaginationComponent';

interface ChaptersProps {
  levelData: any;
}

const ListChapters: React.FC<ChaptersProps> = ({ levelData }) => {
  const [editChapterData, setEditChapterData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | 'delete' | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id ?? null);
    setEditChapterData(data ?? null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteChapterParams = {
    mode: 'chapter',
    type: 'delete',
    id: selectedRowId,
  };

  const fetchChaptersParams = {
    mode: 'chapter',
    type: 'get',
    level_id: levelData.id,
  };

  const { data: listChapters, isLoading, isFetching } = useQuery(
    [queryKeys.listChapters, searchTerm],
    () => FetchData({
      ...fetchChaptersParams,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedChaptersData = decodeResponse(listChapters);
  const breadcrumbItems = [
    {
      title: <FormattedMessage id='certificate' />,
      href: '/certificate',
    },
    {
      title: <FormattedMessage id='chapters' />,
      href: '',
    },
  ];

  return (
    <>
      <FilterTable
        isFilter={false}
        items={breadcrumbItems}
        onSearch={(value) => setSearchTerm(value)}
        buttonText={<FormattedMessage id='add_chapter' />}
        tabName={<FormattedMessage id='chapters' />}
        onClick={() => showModal('add')}
        isUpload={false}
      />

      <UploadFileModal
        visible={isModalVisible && modalAction === 'upload'}
        onClose={closeModal}
        mode={'assessment_advice'}
        confirmationHeader={''}
        successMessage={''}
        queryKey={queryKeys.listAdvice}
      />

      <AddChapter
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
        levelData={levelData}
      />

      <UpdateChapter
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        chapterData={editChapterData}
        levelData={levelData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteChapterParams}
        confirmationHeader={<FormattedMessage id='chapter' />}
        queryKey={queryKeys.listChapters}
        onClose={closeModal}
      />

      <PaginationComponent
        spanNumber={7}
        gutterNumber={10}
        decryptedData={decodedChaptersData?.data}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={(item: any) => (
          <>
            <Col>
              <EditOutlined key='edit' onClick={() => showModal('update', item.id, item)} />
            </Col>
            <Col>
              <DeleteOutlined key='delete' style={{ color: 'red' }} onClick={() => showModal('delete', item.id)} />
            </Col>
          </>
        )}
      />
    </>
  );
};

export default ListChapters;