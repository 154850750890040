import React, { useState } from 'react';
import { Col, Row, Select, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { DeleteOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { deleteData } from '../../../network/deleteData';
import DeleteModal from '../../common/DeleteModal';
import UpdateModal from '../../common/UpdateModal';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import { FormattedMessage } from 'react-intl';
import FilterTable from '../../common/filtersTables/FilterTable';
import UploadFileModal from '../../common/UploadFile';
import { useSelector } from 'react-redux';
import CustomExpandedTable from '../../common/CustomExpandedTable';
import { ColumnsType } from 'antd/es/table';

interface InfoProps {
  assessmentData: any;
}

const QuestionsBank: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const [selectedStatementOne, setSelectedStatementOne] = useState<number | null>(null);

  const showModal = (id: React.Key, action: 'add' | 'update' | "delete" | "upload") => {
    setSelectedRowId(id);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedStatementOne(null);
  };

  const deleteValue = {
    mode: "assessment_question_bank",
    type: "delete",
    id: selectedRowId
  };

  const { data: listQuestions, isLoading, isFetching } = useQuery(
    [queryKeys.listQuestions, searchTerm],
    () =>
      FetchData({
        mode: "assessment_question_bank",
        type: "get",
        assessment_type_id: assessmentData.id,
        statement: searchTerm
      }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listQuestions);
  const decryptedData = decodedResponse?.data.map(decryptItem);

  const { data: listStatements } = useQuery(
    [queryKeys.listStatement],
    () => FetchData({
      mode: "assessment_statement",
      type: "get",
      assessment_type_id: assessmentData.id,
      language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
    }),
    {
      keepPreviousData: true,
    },
  );

  const decodedStatementResponse = decodeResponse(listStatements);
  const decryptedStatementData = decodedStatementResponse?.data.map(decryptItem);
  const filteredStatementOptions = (excludeId: number | null) =>
    decryptedStatementData?.filter((item: any) => item?.id !== excludeId).map((item: any) => ({
      value: item?.id,
      label: item?.statement,
    }));

  const formFields = [
    {
      name: "statement_one_id", label: <FormattedMessage id={'statement_one'} />, rules: [{ required: true, message: "Statement One is required" }], input: <Select
        placeholder="Enter Statement One"
        options={filteredStatementOptions(null)}
        onChange={(value) => setSelectedStatementOne(value)}
      />, colSize: 12
    },
    {
      name: "statement_two_id", label: <FormattedMessage id={'statement_two'} />, rules: [{ required: true, message: "Statement Two is required" }], input: <Select
        placeholder="Enter Statement Two"
        options={filteredStatementOptions(selectedStatementOne)}
      />, colSize: 12
    }
  ];

  const updateFormFields = [
    {
      name: "statement_one_id", label: <FormattedMessage id={'statement_one'} />, rules: [{ required: true, message: "Statement One is required" }], input: <Select
        placeholder="Enter Statement One"
        options={filteredStatementOptions(null)}
        onChange={(value) => setSelectedStatementOne(value)}
      />, colSize: 12
    },
    {
      name: "statement_two_id", label: <FormattedMessage id={'statement_two'} />, rules: [{ required: true, message: "Statement Two is required" }], input: <Select
        placeholder="Enter Statement Two"
        options={filteredStatementOptions(selectedStatementOne)}
      />, colSize: 12
    }
  ];

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='questions_bank' />,
    }
  ];

  const formattedData = decryptedData?.map((record: any) => ({
    key: record.id,
    statement_one: decryptedStatementData?.find((statement: { id: any; }) => statement.id === record.statement_one_id)?.statement,
    statement_two: decryptedStatementData?.find((statement: { id: any; }) => statement.id === record.statement_two_id)?.statement,
    id: record.id
  }));

  const columns: ColumnsType<any> = [
    {
      title: <FormattedMessage id={'statement_one'} />,
      dataIndex: 'statement_one',
    },
    {
      title: <FormattedMessage id={'statement_two'} />,
      dataIndex: 'statement_two',
    },
    {
      key: 'operation',
      width: 100,
      render: (item: any) => (
        <Space>
          <EditOutlined key="edit" onClick={() => showModal(item.id, 'update')} />
          <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal(item.id, 'delete')} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_question' />}
        items={items}
        onClick={() => showModal(1, 'add')}
        onClickUpload={() => showModal(1, 'upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'question'} />}
          </span>
        }
        queryKey={queryKeys.listQuestions}
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        assessmentData={assessmentData.id}
        mode='assessment_question_bank'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'question'} />}
          </span>
        }
        queryKey={queryKeys.listQuestions}
        selectedRowId={selectedRowId}
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={{
          statement_one_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.statement_one_id,
          statement_two_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.statement_two_id,
        }}
        formFields={updateFormFields}
        mode='assessment_question_bank'
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_question_bank"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        queryKey={queryKeys.listQuestions}
        confirmationHeader={<FormattedMessage id={'question'} />}
        onClose={closeModal}
      />

      <CustomExpandedTable
        columns={columns}
        data={formattedData}
        isLoading={isLoading}
        isFetching={isFetching}
        scroll={{ x: 'max-content' }} 
        expandableData={{
          expandRowByClick: true,
          expandedRowRender: (record: {
            statement_one: any;
            statement_two: any;
          }) =>
            <div className="p-0">
              <Row className='bg-[#FAFAFA] p-3 mb-2'>
                <Col span={24} className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                  <FormattedMessage id={'statement_one'} />:
                  <span className='text-[#8C8C8C]'>
                    <br />
                    {record?.statement_one}
                  </span>
                </Col>
                <Col span={24} className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                  <FormattedMessage id={'statement_two'} />:                  <span className='text-[#8C8C8C]'>
                    <br />
                    {record?.statement_two}
                  </span>
                </Col>
              </Row>
            </div>
          ,
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: () => void; record: any }) =>
            expanded ? (
              <span onClick={() => onExpand()}>
                <UpOutlined />
              </span>
            ) : (
              <span onClick={() => onExpand()}>
                <DownOutlined />
              </span>
            ),
          rowExpandable: (record: { id: string; }) => record.id !== 'Not Expandable',
        }}
      />
    </>
  );
};

export default QuestionsBank;