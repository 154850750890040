import {
  Tabs,
  TabsProps,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import Examinee from "../../components/assessment/cpatAssessment/examScheduling/examinee/Examinee";
import { useQuery } from "@tanstack/react-query";
import { ShowDate } from "../../network/showDate";
import { queryKeys } from "../../services/react-query/queryKeys";
import { decodeResponse } from "../../helpers/apiUtils";
import BasicInfo from "../../components/assessment/cpatAssessment/examScheduling/BasicInfo";

export default function AssessmentExamData() {
  const location = useLocation();
  const data = location?.state.exam_configuration;
  const values = {
    mode: "assessment_exam_schedule",
    type: "show",
    id: data.id
  };

  const { data: examData } = useQuery(
    [queryKeys.exam],
    () => ShowDate(values)
  );
  const decodedResponse = decodeResponse(examData);

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'basic_info'} />,
      children: <BasicInfo exam_scheduling_data={decodedResponse?.data} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'examinees'} />,
      children: <Examinee exam_scheduling_data={decodedResponse?.data} />
    }
  ];

  return (
    <>
      <CustomHeader title={
        <div>
          <FormattedMessage id={'exam'} />
        </div>
      } />

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>
  );
}

