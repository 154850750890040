import {
  Tabs,
  TabsProps,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import BasicInfo from "../../components/exam/examMonitoring/examinee/BasicInfo";
import QuestionTracking from "../../components/exam/examMonitoring/examinee/QuestionTracking";

export default function ExamineeMonitoringData() {
  const location = useLocation();
  const data = location?.state.examinee_data;

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'basic_info'} />,
      children: <BasicInfo examinee_data={data} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'question_tracking'} />,
      children: <QuestionTracking examineeData={data} />
    }
  ];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'exam'} />} />

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>

  );
}

