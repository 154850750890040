export const decodeResponse = (response) => {
  try {
    const decodedData = atob(response);
    return JSON.parse(decodedData);
  } catch (error) {
    console.error('Failed to decode response:', error);
    return null;
  }
};

export function decryptItem(item) {
  if (typeof item !== 'object' || item === null) {
    return item;
  }

  const decryptedItem = {};

  Object.keys(item).forEach(key => {
    const value = item[key];

    if (typeof value === 'number') {
      decryptedItem[key] = value;
    } 
    if (typeof value === 'number') {
     
      decryptedItem[key] = value;
    } else {
      decryptedItem[key] = decryptData(value);
    }
  });

  return decryptedItem;
}


export function decryptData(data) {
  if (typeof data !== 'string' || data.length === 0) {
    return '';
  }

  const key = [53, 75, 118, 82, 109, 119, 88, 121, 46, 51, 54, 83, 117, 38, 90, 122, 107, 53];
  const result = [];

  for (let i = 0; i < data.length; i++) {
    result.push(data.charCodeAt(i) ^ key[i % key.length]);
  }

  return String.fromCharCode(...result);
}
