// Role Enum (English and Arabic)
enum RoleEnum {
  Admin = 'admin',
  ELearningAdmin = 'elearning_admin',
  ExamAdmin = 'exam_admin',
  AccreditationAdmin = 'accreditation_admin',
  AssessmentAdmin = 'assessment_admin',
  User = 'user',
  CorporateCenter = 'corporate_center',
  Trainee = 'trainee',
  Batch = 'batch',
  AccreditationCenter = 'accreditation_center',
  AccreditationTrainer = 'accreditation_trainer',
}

// English Role Labels
const roleLabelsEn: { value: string; label: string }[] = [
  { value: RoleEnum.Admin, label: 'Admin' },
  { value: RoleEnum.ELearningAdmin, label: 'E-Learning Admin' },
  { value: RoleEnum.ExamAdmin, label: 'Exam Admin' },
  { value: RoleEnum.AccreditationAdmin, label: 'Accreditation Admin' },
  { value: RoleEnum.AssessmentAdmin, label: 'Assessment Admin' },
  { value: RoleEnum.User, label: 'User' },
  { value: RoleEnum.CorporateCenter, label: 'Corporate Center' },
  { value: RoleEnum.Trainee, label: 'Trainee' },
  { value: RoleEnum.Batch, label: 'Batch' },
  { value: RoleEnum.AccreditationCenter, label: 'Accreditation Center' },
  { value: RoleEnum.AccreditationTrainer, label: 'Accreditation Trainer' },
];

// Arabic Role Labels
const roleLabelsAr: { value: string; label: string }[] = [
  { value: RoleEnum.Admin, label: 'المشرف' },
  { value: RoleEnum.ELearningAdmin, label: 'مشرف التعليم الإلكتروني' },
  { value: RoleEnum.ExamAdmin, label: 'مشرف الامتحانات' },
  { value: RoleEnum.AccreditationAdmin, label: 'مشرف الاعتماد' },
  { value: RoleEnum.AssessmentAdmin, label: 'مشرف التقييم' },
  { value: RoleEnum.User, label: 'مستخدم' },
  { value: RoleEnum.CorporateCenter, label: 'المركز الرئيسي' },
  { value: RoleEnum.Trainee, label: 'متدرب' },
  { value: RoleEnum.Batch, label: 'دفعة' },
  { value: RoleEnum.AccreditationCenter, label: 'مركز الاعتماد' },
  { value: RoleEnum.AccreditationTrainer, label: 'مدرب الاعتماد' },
];

export { RoleEnum, roleLabelsEn, roleLabelsAr };
