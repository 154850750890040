import { InboxOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import React from "react";
import { Button, Form, message, Flex, Modal } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UploadData } from "../../network/uploadDate";
import Dragger from "antd/es/upload/Dragger";
import { FormattedMessage } from "react-intl";

interface UploadFileModalProps<T> {
  visible: boolean;
  onClose: () => void;
  mode: T | null;
  confirmationHeader: string;
  successMessage: string;
  queryKey: string;
}

const UploadFileModal: FC<UploadFileModalProps<any>> = ({
  visible,
  onClose,
  mode,
  confirmationHeader,
  queryKey,
  successMessage,
}) => {
  const [fileValue, setFileValue] = useState<any>([]);
  const queryClient = useQueryClient();

  const { mutate: onUploadFile, isLoading } = useMutation(
    UploadData,
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries([queryKey]);
        onClose();
        message.success(successMessage);
      },
      onError: (error: any) => {
        console.error("Error uploading file:", error);
      },
    }
  );


  const handleUploadFile = ({ file }: any) => {
    let form_data = new FormData();
    form_data.append('mode', mode);
    form_data.append('type', "upload");
    form_data.append('file', fileValue[0]);

    onUploadFile(form_data);
  };

  const handleFileChange = (info: { fileList: any; }) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileValue(fileList.map(file => file.originFileObj));
  };

  const handleRemove = () => {
    setFileValue(null);
  };

  return (
    <Modal
      centered
      open={visible}
      onOk={handleUploadFile}
      onCancel={onClose}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={[]}
      width={850}
    >
      <Form>
        <div className="p-6">
          <Dragger
            onChange={handleFileChange}
            onRemove={handleRemove}
            multiple={false}
            accept=".xlsx"
            showUploadList={false}
          >
            <div className="p-5">
              {fileValue.length > 0 ? (
                <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                  <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                  <span className="text-[#75757E]">{fileValue[0]?.name}</span>
                </div>
              ) : (
                <>
                  <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                    <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                    <span className="text-[#75757E]"><FormattedMessage id={'upload_text1'} /></span>
                  </div>
                  <span className="text-xs font-normal text-[#75757E]">
                    <FormattedMessage id={'upload_text2'} />
                  </span>
                </>
              )}
            </div>
          </Dragger>
        </div>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={onClose}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id='cancel' />
          </Button>
          <Button
            type="text"
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
            loading={isLoading}
            onClick={handleUploadFile}
          >
            <FormattedMessage id='save' />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default UploadFileModal;
