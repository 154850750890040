import { useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { addFormData } from '../../network/storeDate';
import queryClient from '../../services/react-query/queryClient';
import { RcFile } from 'antd/es/upload';
import UploudImgSVG from '../../assets/teamMember/UploudImgSVG';
import { lang } from '../../enum/lang';
import { FormattedMessage } from 'react-intl';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const AddCertificate: React.FC<AddModalProps> = ({
  visible,
  onClose,
}) => {
  const [fileValue, setFileValue] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();

  const { mutate: onAddCertificate, isLoading } = useMutation(addFormData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listCertificates]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });
  const handleFileChange = (info: {
    file: any; fileList: any;
  }) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    getBase64(info.file.originFileObj as RcFile, (url) => {
      setImageUrl(url);
    });
    setFileValue(fileList.map(file => file.originFileObj));
  };

  const onCancel = () => {
    form.resetFields();
    setImageUrl("");
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "certificate");
    form_data.append('type', "store");
    form_data.append('name', values?.name);
    form_data.append('language_id', values?.language_id);
    form_data.append('instruction', encodeURIComponent(values.instruction));
    form_data.append('image', fileValue[0]);

    onAddCertificate(form_data);
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_certificate' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data" 
        className="form-style mt-8 px-9 pb-5"
      >
        <Form.Item
          name='image'
          required={false}
          className="form-style w-full flex justify-center"
        >
          <Upload
            listType="picture-card"
            className="avatar-uploader flex justify-center"
            onChange={handleFileChange}
            showUploadList={false}
          >
            {imageUrl ? <img src={imageUrl} alt="" style={{ width: '100%' }} /> : <UploudImgSVG />}
          </Upload>

        </Form.Item>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              name="name"
              label={<FormattedMessage id='name' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              name="language_id"
              label={<FormattedMessage id='language' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'language is required' },
              ]}
            >
              <Select
                placeholder="choces language"
                options={lang?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default AddCertificate;

