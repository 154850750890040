import { Button, Col, Flex, Form, Input, Modal, Row, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { addELearning } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { FormattedMessage } from 'react-intl';

interface AddModalProps {
  visible: boolean;
  onClose: () => void;
  levelData: any;
}

const AddChapter: React.FC<AddModalProps> = ({
  visible,
  onClose,
  levelData,
}) => {
  const [form] = Form.useForm();

  const { mutate: onAddChapter, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listChapters]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "chapter");
    form_data.append('type', "store");
    form_data.append('name', values.name);
    form_data.append('level_id', levelData?.id);

    onAddChapter(form_data);
  };
  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_chapter' />}
      width={800}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              name="name"
              label={<FormattedMessage id='name' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
        </Row>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default AddChapter;

