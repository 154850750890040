import React, { useState } from 'react';
import { Col, Input, Select } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import UpdateModal from '../../common/UpdateModal';
import AddModal from '../../common/AddModal';
import { StoreData } from '../../../network/storeDate';
import UploadFileModal from '../../common/UploadFile';
import { FormattedMessage } from "react-intl";
import { lang } from '../../../enum/lang';
import { useSelector } from 'react-redux';
import FilterTable from '../../common/filtersTables/FilterTable';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../common/PaginationComponent';

interface InfoProps {
  assessmentData: any;
}

const Category: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };


  const values = {
    mode: "assessment_category",
    type: "get",
    assessment_type_id: assessmentData.id,
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const { data: listCategories, isLoading, isFetching } = useQuery(
    [queryKeys.listCategories, values.language_id, assessmentData.id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const deleteValue = {
    mode: "assessment_category",
    type: "delete",
    id: selectedRowId
  };

  const decodedResponse = decodeResponse(listCategories);
  const decryptedData = (decodedResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['category_code'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const formFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter category name" />, colSize: 12 },
    {
      name: "language_id", label: <FormattedMessage id={'language'} />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        placeholder="choces language"
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
      />
      , colSize: 12
    }
  ];


  const updateFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter category name" />, colSize: 12 },
    {
      name: "language_id", label: <FormattedMessage id={'language'} />,
      rules: [{
        required: true, message: 'language is required'
      }],
      input: <Select
        placeholder="choces language"
        options={lang.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
      />
      , colSize: 12
    }
  ];


  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='categories' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_category' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
        isAdded={decryptedData?.length === 7 ? false : true}
        isUpload={decryptedData?.length === 7 ? false : true}
      />

      <AddModal
        visible={isModalVisible && modalAction === "add"}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'category'} />}
          </span>
        }
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={null}
        formFields={formFields}
        queryKey={queryKeys.listCategories}
        mode='assessment_category'
        assessmentData={assessmentData.id}
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'category'} />}
          </span>
        }
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name,
          category_code: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.category_code,
          detail: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.detail,
          score: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.score,
          language_id: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.language_id,
        }}
        formFields={updateFormFields}
        queryKey={queryKeys.listCategories}
        mode='assessment_category'
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        queryKey={queryKeys.listCategories}
        onClose={closeModal}
        confirmationHeader={<FormattedMessage id={'category'} />}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_category"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listCategories} />

      <PaginationComponent
        spanNumber={6}
        gutterNumber={16}
        decryptedData={decryptedData}
        isLoading={isLoading}
        isFetching={isFetching}
        actions={(item: any) => (
          <>
            <Col><Link to="/subcategories" style={{ color: "rgba(0, 0, 0, 0.88)" }} state={{ categoryData: item }} ><EyeOutlined /></Link></Col>
            <Col><EditOutlined key="edit" onClick={() => showModal('update', item.id)} /></Col>
            <Col><DeleteOutlined key="delete" className='text-red-400' onClick={() => showModal('delete', item.id)} /></Col>
          </>
        )}
      />
    </>
  );
};

export default Category;
