import React, { useState } from 'react';
import { Row, Col, Card, Empty, Pagination, Spin, Flex } from 'antd';
import { FormattedMessage } from 'react-intl';

interface DataItem {
  id: string;
  name?: string;
  chapter?: { name?: string };
  advice?: string;
}

interface Props {
  decryptedData: any;
  actions: (item: DataItem) => React.ReactNode;
  gutterNumber: number;
  spanNumber: number;
  isLoading?: boolean;
  isFetching?: boolean;
}

const PaginationComponent: React.FC<Props> = ({ isFetching, decryptedData, actions, gutterNumber, spanNumber, isLoading = false }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handlePageChange = (page: number) => setCurrentPage(page);

  const truncateText = (text: string, maxLength: number): string =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  const itemsPerPage = 12;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  const getDisplayText = (item: DataItem) => {
    const text = item.name || item.chapter?.name || item.advice || '';
    return truncateText(text, 40);
  };

  return (
    <>
      {isLoading || isFetching ? (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      ) : decryptedData && decryptedData.length > 0 ? (
        <>
          <Row gutter={gutterNumber}>
            {currentItems.map((item: any) => (
              <Col key={item.id} className='mb-3' xl={spanNumber} sm={24} md={12}>
                <Card style={{ width: '100%' }} className='rounded-sm'>
                  <Row justify='space-between' gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 12 }}>
                    <Col>{getDisplayText(item)}</Col>
                    <Col>
                      <Row justify='space-between' gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                        {actions(item)}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
          <Flex justify='end'>
            <Pagination
              current={currentPage}
              total={decryptedData?.length}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              className='mt-1'
            />
          </Flex>
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default PaginationComponent;