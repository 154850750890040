import React, { useEffect } from "react";
import { Button, Col, Flex, Form, message, Modal, Row } from "antd";
import { useMutation } from "@tanstack/react-query";
import queryClient from "../../services/react-query/queryClient";
import { FormattedMessage, useIntl } from "react-intl";

interface UpdateModalProps<T> {
    visible: boolean;
    onClose: () => void;
    onSubmit: (data: T) => Promise<void>;
    initialValues: T | null;
    confirmationHeader: any;
    selectedRowId: any;
    mode: string;
    type?: string;
    queryKey: string;
    formFields: {
        colSize: any, input: any; name: string; label: any; placeholder?: string; rules?: any[]
    }[];
}

const UpdateModal: React.FC<UpdateModalProps<any>> = ({
    visible,
    onClose,
    onSubmit,
    initialValues,
    confirmationHeader,
    formFields,
    selectedRowId,
    queryKey,
    type,
    mode,
}) => {
    const intl = useIntl();
    const successMessage = intl.formatMessage({ id: 'success_update_msg' });
    const { mutate: updateMutation, isLoading } = useMutation(onSubmit, {
        onSuccess: () => {
            onClose();
            queryClient.invalidateQueries([queryKey]);
            message.success(successMessage);
        },
        onError: (error) => {
            message.error("Error Updateing");
        },
    });

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const handleFormFinish = async (values: any) => {
        const { start_date, end_date, scheduled_at, ...otherValues } = values;
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : null;
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : null;
        const formattedScheduledAt = scheduled_at ? scheduled_at.format('YYYY-MM-DD HH:mm:ss') : null;

        const formData = {
            mode: mode,
            type: type || "update",
            language_id: 1,
            id: selectedRowId,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            scheduled_at: formattedScheduledAt,
            ...otherValues
        };
        updateMutation(formData);
    };

    const [form] = Form.useForm();
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues);
        }
    }, [visible, initialValues, form]);
    return (
        <Modal
            centered
            open={visible}
            className="customModal"
            title={confirmationHeader}
            onOk={handleFormFinish}
            onCancel={onCancel}
            footer={[]}
            width={850}
        >
            <div className="px-5 pb-6">
                <Form form={form} initialValues={initialValues} layout="vertical" onFinish={handleFormFinish}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 22 }} justify={"space-between"}>
                        {formFields.map((field) => (
                            <Col span={field.colSize} key={field.name}>
                                <Form.Item required={false} name={field.name} label={field.label} rules={field.rules}>
                                    {field.input}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>


                    <Flex gap="middle" align="center" justify="flex-end">
                        <Button
                            type="text"
                            onClick={onCancel}
                            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
                            htmlType="button"
                        >
                            <FormattedMessage id='cancel' />
                        </Button>
                        <Button
                            type="text"
                            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            <FormattedMessage id='save' />
                        </Button>
                    </Flex>
                </Form>
            </div>
        </Modal>
    );
};

export default UpdateModal;
