import React from 'react';
import { Button, Col, Dropdown, Flex, message, Row, Space, Tag } from 'antd';
import Message from '../../../assets/common/Message';
import Phone from '../../../assets/common/Phone';
import Contacts from '../../../assets/common/Contacts';
import Address from '../../../assets/common/Address ';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  LinkOutlined, EditOutlined
} from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { editFormData } from '../../../network/editData';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { status, statusEnum } from '../../../enum/status';
import { MenuProps } from 'antd/lib';
import { useSelector } from 'react-redux';

interface BasicInfoProps {
  AccreditationCenterData: any;
}

const BasicInfo: React.FC<BasicInfoProps> = ({ AccreditationCenterData }) => {
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const { mutate: onUpdateAccreditationCenter, isLoading } = useMutation(editFormData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.SpacficAccreditationCenter]);
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "accreditation_center");
    form_data.append('type', "update");
    form_data.append('id', AccreditationCenterData.id);
    form_data.append('status', values?.status);

    onUpdateAccreditationCenter(form_data);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const selectedStatus = e.key;
    onFinish({ status: selectedStatus });
  };

  const menuItems = status.map(({ value, label }) => ({
    key: value,
    label: currentLang === 'ar' ? label.ar : label.en,
  }));

  const getStatusColor = (statusValue: string) => {
    switch (statusValue) {
      case statusEnum.Pending:
        return 'orange';
      case statusEnum.Completed:
        return 'green';
      case statusEnum.Canceled:
        return 'red';
      default:
        return 'blue';
    }
  };

  return (
    <div className='mt-5'>
      <Flex justify='flex-end'>
        <Tag color={getStatusColor(AccreditationCenterData?.status)} className='text-center text-white py-1'>
          {currentLang === 'ar' ? status.find(s => s.value === AccreditationCenterData?.status)?.label.ar : status.find(s => s.value === AccreditationCenterData?.status)?.label.en}
        </Tag>

        <Dropdown
          menu={{ items: menuItems, onClick: handleMenuClick }}
          trigger={['click']}
        >
          <Button loading={isLoading}>
            <Space>
              <EditOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Flex>

      <Row className='mb-4'>
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col className='pt-1' >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3  text-[#0E103A] text-[16px] text-center font-extrabold'>
              <FormattedMessage id={'basic_information'} />:
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Contacts /></div>
                <div>{AccreditationCenterData?.name}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Phone /></div>
                <div>{AccreditationCenterData?.phone}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Message /></div>
                <div>{AccreditationCenterData?.email}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row >
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col className='pt-1' >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3  text-[#0E103A] text-[16px] text-center font-extrabold'>
              <FormattedMessage id={'contact_person'} />:
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Contacts /></div>
                <div>
                  {AccreditationCenterData?.contact_person}
                  <br />
                  {AccreditationCenterData?.contact_title}
                </div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Phone /></div>
                <div>{AccreditationCenterData?.contact_phone}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><Message /></div>
                <div>{AccreditationCenterData?.contact_email}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row className='mb-4'>
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col className='pt-1' >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3 text-[#0E103A] text-[16px] text-center font-extrabold'>
              <FormattedMessage id={'location'} />:
            </Col>
          </Row>
        </Col>

        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Address /></div>
                <div>{AccreditationCenterData?.country}</div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><Address /></div>
                <div>{AccreditationCenterData?.city}</div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row className='mb-4'>
        <Col xl={6}>
          <Row className='w-[200px] bg-[#FAFAFA] p-2'>
            <Col className='pt-1' >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
              </svg>
            </Col>
            <Col className='px-3  text-[#0E103A] text-[16px] text-center font-extrabold'>
              <FormattedMessage id={'files'} />:
            </Col>
          </Row>
        </Col>
        <Col className='p-2'>
          <Row>
            <Col className='w-[200px]'>
              <Flex>
                <div className='px-2'><LinkOutlined /></div>
                <div>
                  <Link className='text-[#0E103A] text-center font-extrabold' to={AccreditationCenterData?.tax_registration} target='_blank'> <FormattedMessage id={'tax_registration'} /></Link>
                </div>
              </Flex>
            </Col>
            <Col className='w-[200px]'>
              <Flex >
                <div className='px-2'><LinkOutlined /></div>
                <div>
                  <Link className='text-[#0E103A] text-center font-extrabold' to={AccreditationCenterData?.commercial_registration} target='_blank'> <FormattedMessage id={'commercial_registration'} /></Link>
                </div>
              </Flex>
            </Col>
            <Col className='w-[150px]'>
              <Flex>
                <div className='px-2'><LinkOutlined /></div>
                <div>
                  <Link className='text-[#0E103A] text-center font-extrabold' to={AccreditationCenterData?.license} target='_blank'> <FormattedMessage id={'license'} /></Link>
                </div>
              </Flex>
            </Col>
            <Col className='w-[150px]'>
              <Flex>
                <div className='px-2'><LinkOutlined /></div>
                <div>
                  <Link className='text-[#0E103A] text-center font-extrabold' to={AccreditationCenterData?.accreditation} target='_blank'> <FormattedMessage id={'accreditation'} /></Link>
                </div>
              </Flex>
            </Col>
            <Col className='w-[150px]'>
              <Flex>
                <div className='px-2'><LinkOutlined /></div>
                <div>
                  <Link className='text-[#0E103A] text-center font-extrabold' to={AccreditationCenterData?.quality_manual} target='_blank'> <FormattedMessage id={'quality_manual'} /></Link>
                </div>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default BasicInfo;
