import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

function OwnNotFound() {
  const OwnNotFoundStyles = css`
    height: 100vh;
    background-color: #fafafa;

    .content {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 12rem;

      h2 {
        font-size: 6rem;
        /* line-height: 1.6; */
        font-weight: 700;
        color: #ed4f4f;
        text-align: center;
        margin-bottom: -3rem;
      }

      h1 {
        font-size: 6rem;
        line-height: 1.6;
        font-weight: 700;
        color: #282c34;
        text-align: center;
      }

      .description {
        margin: 3rem 0;

        p {
          font-size: 1.8rem;
          font-weight: 300;
          color: #6d6d6d;
          text-align: center;
          max-width: 48rem;
        }

        p:last-child {
          font-size: 1.6rem;
          line-height: 1.7;
          font-weight: 600;
          margin-top: 2.5rem;
        }
      }

      img {
        width: 16rem;
      }
    }
  `;

  return (
    <div className={OwnNotFoundStyles}>
      <div className="content">
        <h2>404</h2>
        <h1>Page Not Found</h1>
        <div className="description">
          <p>We couldn't find what you were looking for.</p>
          <p>
            Please contact the owner of the site that linked you to the original
            URL and let them know their link is broken.
          </p>
        </div>
        <Link to="/">
          {/* <img src={logo} title="Go Home" alt="tawredaat" /> */}
          home
        </Link>
      </div>
    </div>
  );
}

export default OwnNotFound;
