import Assessment from "../pages/assessment/Assessment";
import AssessmentData from "../pages/assessment/AssessmentData";
import AssessmentSubCategoryData from "../pages/assessment/AssessmentSubCategoryData";
import CpatAssessmentData from "../pages/assessment/CpatAssessmentData";
import AssessmentExamData from "../pages/assessment/ExamSchedulingData";

const routesAssessment = [
  {
    path: "assessment",
    element: <Assessment />,
  },
  {
    path: "assessment/data",
    element: <AssessmentData />,
  },
  {
    path: "assessment/cpat",
    element: <CpatAssessmentData />,
  },
  {
    path: "subcategories",
    element: <AssessmentSubCategoryData />,
  },
  {
    path: "assessment/exam/data",
    element: <AssessmentExamData />,
  },
];

export default routesAssessment;
