import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Row, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddAssessment from './AddAssessment';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateAssessment from './UpdateAssessment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../common/UploadFile';

function Assessment() {
  const [editAssessmentData, setEditAssessmentData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditAssessmentData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "assessment",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "assessment",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const { data: listAssessments, isLoading } = useQuery(
    [queryKeys.listAssessments, values.language_id],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listAssessments);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['media'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const renderAssessment = () => {
    if (isLoading) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decryptedData || decryptedData?.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
          {decryptedData?.map((item: any) =>
            <Col className='mb-4' xl={7} lg={7} md={12} sm={24} xs={24}>
              <Card title={item?.name}
                actions={[
                  <Link
                    to={item.id === 1 ? "/assessment/cpat" : "/assessment/data"}
                    className='text-[#ED1D23]' state={{ assessment_data: item }}><EyeOutlined /></Link>,
                  <EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} />,
                  <DeleteOutlined style={{ color: "red" }} key="delete" onClick={() => showModal('delete', item.id)} />,
                ]}
              >
                <Flex align='center'>
                  <img
                    alt={item.name}
                    src={item.media}
                    className='w-full'
                    style={{ height: "120px" }}
                  />
                </Flex>
              </Card>

            </Col>
          )}
        </Row>
      </>
    );
  };

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_assessment' />}
        isAdded={false}
        isSearch={false}
        isUpload={false}
        isFilter={false}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAssessments} />

      <AddAssessment
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <UpdateAssessment
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        assessmentData={editAssessmentData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='assessment' />}
        queryKey={queryKeys.listAssessments}
        onClose={closeModal}
      />
      {renderAssessment()}
    </>
  );
}

export default Assessment;
